import React, { useState, useEffect } from 'react'
import { ScratchCard, SCRATCH_TYPE } from "scratchcard-js"
import { useTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"
import Snowfall from 'react-snowfall'
import toast from 'react-hot-toast'

import WithService from '../../services/hoc'
import { useCookie } from '../../utils/cookie.js'

import { useDispatch, useSelector } from 'react-redux'

import './event.sass'

import brushImg from './img/ui/brush.png'
import overlayImg from './img/ui/overlay.jpg'
import lineImg from './img/ui/line-stock.svg'

import snowflakeImg from './img/ui/_snowflake/1.png'
import { handleEvent } from '../../redux/actions'
import ReactFlipCard from 'reactjs-flip-card'
import { checkImage } from '../../utils/checkImage.js'
import Spinner from '../../components/Spinner/Spinner.jsx'
import { useRef } from 'react'
import { copyTextToClipboard } from '../../utils/copyTextToClipboard.js'

const snowflake1 = document.createElement('img')
snowflake1.src = snowflakeImg
const images = [snowflake1]

const PERCENT_TO_FINISH = 50

const dateDifference = (promoExpiredAt, promoExpiresBlock, hasCookies, setRedirect, dispatch, handleEvent) => {
  const d1 = new Date()
  const d2 = new Date(promoExpiredAt * 1000)

  const dif = (d2 - d1) / 1000
  const hours = Math.floor(dif / 3600)
  const minutes = Math.floor((dif - hours * 3600) / 60)
  const seconds = Math.floor(dif - hours * 3600 - minutes * 60)
  promoExpiresBlock.innerHTML = `${hours}:${(minutes < 10) ? `0${minutes}` : minutes}:${(seconds < 10) ? `0${seconds}` : seconds}`

  let interval = setInterval(() => {
    const d1 = new Date()
    const d2 = new Date(promoExpiredAt * 1000)

    const dif = (d2 - d1) / 1000
    const hours = Math.floor(dif / 3600)
    const minutes = Math.floor((dif - hours * 3600) / 60)
    const seconds = Math.floor(dif - hours * 3600 - minutes * 60)

    if (dif <= 0) {
      clearInterval(interval)

      if (hasCookies) {
        useCookie.delete('eventToken')
        useCookie.delete('eventPromo')
        useCookie.delete('eventExpiredAt')
      }

      dispatch(handleEvent('eventToken', null))
      dispatch(handleEvent('eventPromo', null))
      dispatch(handleEvent('eventExpiredAt', null))

      promoExpiresBlock.innerHTML = '00:00:00'
      return setRedirect(true)
    }

    promoExpiresBlock.innerHTML = `${hours}:${(minutes < 10) ? `0${minutes}` : minutes}:${(seconds < 10) ? `0${seconds}` : seconds}`
  }, 1000)
}

const Event = ({ imgNumber, folder, calendarDate, isSnowfall, isFlip, imgType, ...props }) => {
  const { t } = useTranslation()

  const [currentImgNumber, setCurrentImgNumber] = useState(imgNumber)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [loadingFetch, setLoadingFetch] = useState(false)

  const codeRef = useRef(null)

  const [promo, setPromo] = useState(false)
  const [clickedFlip, setClickedFlip] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const hasCookies = useSelector((state) => state.cookies)
  const { eventToken, eventPromo, eventExpiredAt } = useSelector((state) => state.event)
  const dispatch = useDispatch()

  const exit = (redirect = false) => {
    console.log('exit')
    if (hasCookies) {
      useCookie.delete('eventToken')
      useCookie.delete('eventPromo')
      useCookie.delete('eventExpiredAt')
    }

    dispatch(handleEvent('eventToken', null))
    dispatch(handleEvent('eventPromo', null))
    dispatch(handleEvent('eventExpiredAt', null))

    redirect && setRedirect(true)
  }

  useEffect(() => {
    document.title = `${t("event.header.current")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    if (!((hasCookies && useCookie.get('eventToken')) || eventToken)) {
      // TODO
      console.log('no token')
      return exit(true)
    }

    if (hasCookies && useCookie.get('eventToken')) {
      dispatch(handleEvent('eventToken', useCookie.get('eventToken')))
    } else if (eventToken) {
      if (hasCookies) useCookie.set('eventToken', eventToken)
    }

    if (isFlip) {
      checkImage(`./img/event/${folder}/${currentImgNumber}.webp`)
        .then(() => {
          checkImage(`./img/event/${folder}/${currentImgNumber}_cover.webp`)
            .then(() => setLoading(false))
            .catch(() => {
              console.log('there is no first cover in webp format')
              setError('1')
              setCurrentImgNumber(1)
              checkImage(`./img/event/${folder}/${1}.webp`)
                .then(() => {
                  checkImage(`./img/event/${folder}/${1}_cover.webp`)
                    .then(() => setLoading(false))
                    .catch(() => {
                      setError('2')
                      console.log('there is no first cover in webp format')
                      exit()
                    })
                })
                .catch(() => {
                  setError('3')
                  console.log('there is no first image in webp format')
                  exit()
                })
            })
        }).catch(() => {
          setError('4')
          console.log('there is no current image in webp format')
          setCurrentImgNumber(1)
          checkImage(`./img/event/${folder}/${1}.webp`)
            .then(() => {
              checkImage(`./img/event/${folder}/${1}_cover.webp`)
                .then(() => setLoading(false))
                .catch(() => {
                  setError('5')
                  console.log('there is no first cover in webp format')
                  exit()
                })
            })
            .catch(() => {
              setError('6')
              console.log('there is no first image in webp format')
              exit()
            })
        })
    }

    if (!isFlip) {
      const startFunction = new Promise((resolve, reject) => {
        let img = document.createElement('img')
        img.src = `./img/event/${folder}/${currentImgNumber}.${imgType}`
        img.style.display = 'none'

        document.body.appendChild(img)
        img.onerror = () => { img.remove(); resolve(1) }
        img.onload = () => { img.remove(); resolve(currentImgNumber) }
      })

      startFunction.then((checkedImgNumber) => {
        const scBlock = document.querySelector(".event-block__scratch")
        const sc = new ScratchCard("#js--sc--container", {
          scratchType: SCRATCH_TYPE.BRUSH,
          containerWidth: scBlock?.offsetWidth,
          containerHeight: scBlock?.offsetHeight,
          brushSrc: `${brushImg}`,
          imageForwardSrc: `${overlayImg}`,
          imageBackgroundSrc: `./img/event/${folder}/${checkedImgNumber}.${imgType}`,
          percentToFinish: PERCENT_TO_FINISH,
          callback: function () {
            const promoExpiresBlock = document.querySelector("#promoExpires")
            const promoCodeBlock = document.querySelector("#promoCodeBlock")

            if (
              !((hasCookies && useCookie.get('eventPromo')) || eventPromo)
              && !((hasCookies && useCookie.get('eventExpiredAt')) || eventExpiredAt)
            ) {
              setLoadingFetch(true)
              const { Service } = props
              Service.postPromo((hasCookies && useCookie.get('eventToken')) || eventToken)
                .then(({ result: response }) => {
                  const promoCode = response.code
                  const promoExpiredAt = response.expiredAt
                  const expires = new Date(response.expiredAt * 1000)

                  promoCodeBlock.innerHTML = promoCode
                  if (hasCookies) {
                    useCookie.set('eventPromo', promoCode, { 'expires': expires })
                    useCookie.set('eventExpiredAt', promoExpiredAt, { 'expires': expires })
                  }
                  dispatch(handleEvent('eventPromo', promoCode))
                  dispatch(handleEvent('eventExpiredAt', promoExpiredAt))

                  dateDifference(promoExpiredAt, promoExpiresBlock, hasCookies, setRedirect, dispatch, handleEvent)
                  setLoadingFetch(false)
                })
                .then(() => setPromo(true))
                .catch((error) => {
                  setError(error.message)
                  console.log('error in fetch postPromo')
                  exit()
                })
            } else {
              promoCodeBlock.innerHTML = (hasCookies && useCookie.get('eventPromo')) || eventPromo

              dateDifference(
                (hasCookies && useCookie.get('eventExpiredAt')) || eventExpiredAt,
                promoExpiresBlock, hasCookies, setRedirect, dispatch, handleEvent
              )
              setPromo(true)
            }
          }
        })

        sc.init().catch((error) => {
          setError('7')
          console.log(error.message)
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (redirect) return <Redirect to='/login' />
  if (loading && isFlip) return <Spinner />

  return (<>
    {isSnowfall &&
      <Snowfall
        color='#dee4fd'
        snowflakeCount={60}
        speed={[0, 0.5]}
        wind={[0, 1]}
        radius={[5, 9]}
        images={images}
        rotationSpeed={[-1, 0.5]}
        style={{
          zIndex: 100,
          position: 'fixed',
          width: '100vw',
          height: '100vh',
        }}
      />
    }

    <section className="event">
      <div className="container">

        <div className="event-block">
          {isFlip ? (
            <>
              <div className='event-block__flip'>
                <div className={`event-block__flip-desc ${((!promo) && 'active')}`}>
                  <span>{t("event.desc_flip")}</span>
                  <img src={lineImg} alt="line" />
                </div>

                <ReactFlipCard
                  containerCss="event-block__flip_container"
                  direction={'diagonal'}
                  onClick={() => {
                    if (!clickedFlip) {
                      setTimeout(() => {
                        codeRef.current?.scrollIntoView({ behavior: 'smooth' })
                      }, 500)
                      setTimeout(() => {
                        setClickedFlip(true)
                      }, 1000)
                    }

                    const promoExpiresBlock = document.querySelector("#promoExpires")
                    const promoCodeBlock = document.querySelector("#promoCodeBlock")

                    if (
                      !((hasCookies && useCookie.get('eventPromo')) || eventPromo)
                      && !((hasCookies && useCookie.get('eventExpiredAt')) || eventExpiredAt)
                    ) {
                      const { Service } = props
                      Service.postPromo((hasCookies && useCookie.get('eventToken')) || eventToken)
                        .then(({ response }) => {
                          const promoCode = response.code
                          const promoExpiredAt = response.expiredAt
                          const expires = new Date(response.expiredAt * 1000)

                          promoCodeBlock.innerHTML = promoCode
                          if (hasCookies) {
                            useCookie.set('eventPromo', promoCode, { 'expires': expires })
                            useCookie.set('eventExpiredAt', promoExpiredAt, { 'expires': expires })
                          }
                          dispatch(handleEvent('eventPromo', promoCode))
                          dispatch(handleEvent('eventExpiredAt', promoExpiredAt))

                          dateDifference(promoExpiredAt, promoExpiresBlock, hasCookies, setRedirect, dispatch, handleEvent)
                        })
                        .then(() => setPromo(true))
                        .catch((error) => {
                          setError(error.message)
                          console.log('error in fetch postPromo')
                          exit()
                        })
                    } else {
                      promoCodeBlock.innerHTML = (hasCookies && useCookie.get('eventPromo')) || eventPromo

                      dateDifference(
                        (hasCookies && useCookie.get('eventExpiredAt')) || eventExpiredAt,
                        promoExpiresBlock, hasCookies, setRedirect, dispatch, handleEvent
                      )
                      setPromo(true)
                    }

                  }}
                  flipCardStyle={{ transitionDuration: '1s' }}
                  frontComponent={
                    <img src={`./img/event/${folder}/${currentImgNumber}_cover.webp`} alt="" />
                  }
                  backComponent={
                    <img src={`./img/event/${folder}/${currentImgNumber}.webp`} alt="" />
                  }
                  flipTrigger='onClick'
                />
              </div>

              <div ref={codeRef} className={`event-block__promo ${((promo) ? 'active' : '')}`}>
                <div className="event-block__promo-title">{t("event.promo")}</div>
                <div
                  className="event-block__promo-promo"
                  id='promoCodeBlock'
                  onClick={(e) => {
                    copyTextToClipboard(e.currentTarget.textContent, (v) => {
                      if (v === 'success')
                        toast.success(t("event.copy"))
                    })
                  }}
                ></div>
                <div className="event-block__promo-text">{t("event.text")}</div>
                <div className="event-block__promo-next">
                  {t("event.timeout")}
                  <div className='date' id='promoExpires'></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="event-block__scratch">
                <div className="sc__wrapper">
                  <div id="js--sc--container" className="sc__container" />
                </div>

                <div className={`event-block__scratch-desc ${((!promo) && 'active')}`}>
                  <div className="">
                    <span>{t("event.desc")}</span>
                    <img src={lineImg} alt="line" />
                  </div>
                </div>

              </div>

              {!error && loadingFetch && <Spinner onSmall />}

              {!!error ? <div className='error'>Error {error}</div> : (
                <div className={`event-block__promo ${((promo) ? 'active' : '')}`}>
                  <div className="event-block__promo-title">{t("event.promo")}</div>
                  <div
                    className="event-block__promo-promo"
                    id='promoCodeBlock'
                    onClick={(e) => {
                      copyTextToClipboard(e.currentTarget.textContent, (v) => {
                        if (v === 'success')
                          toast.success(t("event.copy"))
                      })
                    }}
                  ></div>
                  <div className="event-block__promo-text">{t("event.text")}</div>
                  <div className="event-block__promo-next">
                    {t("event.timeout")}
                    <div className='date' id='promoExpires'></div>
                  </div>
                </div>
              )}

            </>
          )}
        </div>
      </div>
    </section >
  </>)
}

export default WithService()(Event)