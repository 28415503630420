import React from 'react'
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Buttons from '../Buttons/Buttons'
import LanguageSelect from '../LanguageSelect/LanguageSelect'

import './nav.sass'


const Nav = ({ onClose }) => {
  const { t } = useTranslation()
  const language = useSelector((state) => state.language)

  const onOverlay = (e) => {
    if (e.target === e.currentTarget) onClose()
  }


  return (
    <nav className="nav">
      <div className="nav-overlay" onClick={onOverlay}>
        <div className="nav-wrapper">
          <div>

            <div className="nav-header">

              <LanguageSelect />

              <div onClick={onClose} className="nav-close">
                <svg id="times" viewBox="0 0 352 512"><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
              </div>
            </div>

            <div className="nav-links">
              <ul className="nav-links__top nav-link">
                <li className="active"><a href={`https://lk.carguru.lv/${language}/auth/sign-up`}>
                  {t("nav.registration")}
                </a></li>
                <li><a href={`https://lk.carguru.lv/${language}/auth/sign-in`}>
                  {t("nav.login")}
                </a></li>
              </ul>



              <div className="nav-links__buttons">
                <Buttons onDark />
              </div>

              <ul className="nav-links__bottom nav-link">
                <li><NavLink
                  to='/about'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.about")}
                </NavLink></li>

                <li><NavLink
                  to='/rates'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.rates")}
                </NavLink></li>

                <li><NavLink
                  to='/loyalty'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.loyalty")}
                </NavLink></li>


                <li><NavLink
                  to='/guide'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.guide")}
                </NavLink></li>

                <li><NavLink
                  to='/faq'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.faq")}
                </NavLink></li>

                <li><NavLink
                  to='/business'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.business")}
                </NavLink></li>

                <li><NavLink
                  to='/news'
                  onClick={onClose}
                  activeClassName="active"
                >
                  {t("nav.news")}
                </NavLink></li>
              </ul>
            </div>

          </div>

          <div className="nav-contacts">
            <div className="nav-contact nav-contact__phone">
              <a href={`tel:${t("contacts.phone")}`}>{t("contacts.phone")}</a>
            </div>
            <div className="nav-contact nav-contact__mail">
              <a href={`mailto:${t("contacts.mail_info")}`}>{t("contacts.mail_info")}</a>
            </div>

            <div className="nav-contact nav-contact__address">
              <span>{t("contacts.address")}</span>
            </div>
          </div>

        </div>
      </div>
    </nav>
  )
}

export default Nav