import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Page from '../../../../components/Page/Page'

import './referral.sass'

const Referral = (props) => {
  const { t } = useTranslation()
  const language = useSelector((state) => state.language)


  useEffect(() => {
    document.title = `${t("loyalty.referral-program.title")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])


  return (
    <section className="referral">
      <div className="referral-main__wrapper">
        <div className="container">
          <div className="referral-main">
            <h2 className='referral-main__title'>{t("loyalty.referral-program.main.title")}</h2>
            <div className="referral-main__items">

              <div className="referral-main__item">
                <h3 className="referral-main__item-title"><Trans>{t("loyalty.referral-program.main.item_1.title")}</Trans></h3>
                <span className="referral-main__item-subtitle"><Trans>{t("loyalty.referral-program.main.item_1.subtitle")}</Trans></span>
              </div>

              <div className="referral-main__item">
                <h3 className="referral-main__item-title"><Trans>{t("loyalty.referral-program.main.item_2.title")}</Trans></h3>
                <span className="referral-main__item-subtitle"><Trans>{t("loyalty.referral-program.main.item_2.subtitle")}</Trans></span>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <Page>
          <div className="guide-info referral-instruction">
            <div className="guide-items">

              <div className="guide-item">
                <span className="guide-item__num">1.</span>
                <div className="guide-item__img">
                  <img src={`/img/loyalty/referral/1/${language}.jpg`} alt="registration" />
                </div>
                <div className="guide-item__text referral-instruction__text">
                  <h3>{t("loyalty.referral-program.instruction.item_1.title")}</h3>
                  <p><Trans>{t("loyalty.referral-program.instruction.item_1.subtitle")}</Trans></p>
                </div>
              </div>

              <div className="guide-item">
                <span className="guide-item__num">2.</span>
                <div className="guide-item__img">
                  <img src={`/img/loyalty/referral/2/${language}.jpg`} alt="registration" />
                </div>
                <div className="guide-item__text referral-instruction__text">
                  <h3>{t("loyalty.referral-program.instruction.item_2.title")}</h3>
                  <p><Trans>{t("loyalty.referral-program.instruction.item_2.subtitle")}</Trans></p>
                </div>
              </div>

              <div className="guide-item">
                <span className="guide-item__num">3.</span>
                <div className="guide-item__img">
                  <img src={`/img/loyalty/referral/3/${language}.jpg`} alt="registration" />
                </div>
                <div className="guide-item__text referral-instruction__text">
                  <h3>{t("loyalty.referral-program.instruction.item_3.title")}</h3>
                  <p><Trans>{t("loyalty.referral-program.instruction.item_3.subtitle")}</Trans></p>
                </div>
              </div>

              <div className="guide-item">
                <span className="guide-item__num">4.</span>
                <div className="guide-item__img">
                  <img src={`/img/loyalty/referral/4/${language}.jpg`} alt="registration" />
                </div>
                <div className="guide-item__text referral-instruction__text">
                  <h3>{t("loyalty.referral-program.instruction.item_4.title")}</h3>
                  <p><Trans>{t("loyalty.referral-program.instruction.item_4.subtitle")}</Trans></p>
                </div>
              </div>

            </div>
          </div>
        </Page>

      </div>
    </section>
  )
}
export default Referral