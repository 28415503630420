export const PrivacyEN = () => {
	return (
		<div className='legal-privacy'>
			<h1>Data Processing Notice</h1>

			<p>
				SLYFOX SIA (hereinafter – the Lessor) is very committed to the privacy of users (hereinafter also – the Lessee) and other persons. Data privacy and protection of a users’ private life in data processing is an important issue that is taken into consideration by SLYFOX SIA in its business processes.
			</p>

			<p>
				SLYFOX SIA processes the personal data of users only in accordance with applicable legislation, in particular General Data Protection Regulation (EU) 2016/679 (hereinafter – the GDPR), as well as the applicable legislation of the Republic of Latvia, namely, the Personal Data Processing Law and associated Cabinet Regulations. The terms used herein are consistent with those used in the Agreement.
			</p>

			<h2>
				1) What personal data of the Lessee and for what purposes does the Lessor process?
			</h2>

			<div className="overflow">
				<table>
					<tr>
						<th>Type of data processing and data categories</th>
						<th>What does it mean?</th>
						<th>Legal basis</th>
					</tr>
					<tr>
						<td>
							Data necessary for identification of the Lessee and registration of a user account
						</td>
						<td>
							<p>
								To be in a position to sign an agreement with the Lessee, it is necessary for the Lessor to obtain personal data identifying the user, inter alia, the Lessor’s given name, surname, date of birth.
							</p>
							<p>
								This data and the contact details of the Lessor (phone number, e-mail or postal address) are also used to create the individual account of the Lessor.
							</p>
							<p>
								Where the Lessee is a legal entity, the said information will be required in regard to the authorised representative or contact person of the said entity.
							</p>
						</td>
						<td>
							<p>Article 6(1)(b) of the GDPR</p>
							<p>
								Article 6(1)(f) of the GDPR – legitimate interests: protection of the Lessor against fraud or misuse of services; enhancement of the lease and other processes; improvement of service quality
							</p>
						</td>
					</tr>
					<tr>
						<td>
							Use of cars – place of departure and final destination; time of departure and arrival at the destination; type and duration of use; as well as the distance covered (km)
						</td>
						<td>
							<p>
								Data of the Lessor’s activities under the lease, namely, data as to the place of departure and final destination; time of departure and arrival at the destination; type and duration of use; as well as the distance covered (km) are collected and stored while the Lessor has an active User account. The Lessor can view this data at any time using the Mobile app. The above data is used to the extent required for the performance of the Agreement. The above data is used as the basis for the fee calculation.
							</p>
							<p>
								Moreover, the above date is also used to detect and correct any errors or deficiencies within the lease process and general operations by using anonymised data as far as possible.
							</p>
						</td>
						<td>
							<p>Article 6(1)(b) of the GDPR</p>
							<p>
								Article 6(1)(f) of the GDPR – legitimate interests: – enhancement of the lease and other processes; improvement of service quality
							</p>
						</td>
					</tr>
					<tr>
						<td>Video recordings of the Vehicel surroundings (only applies to Tesla cars)</td>
						<td>
							Tesla Vehicles are equipped by default with video surveillance that monitors the exterior of the Vehicle to ensure its safety and to enable the use of its features such as autopilot or traffic light and road sign recognition. This feature cannot be disabled or configured by the Lessor. During this monitoring, part of the data is saved on the Vehicles disk, which is accessible to the Lessor and Lessee using the touch screen. The Lessor can use the video recordings if they are necessary to prove and investigate any damage to the Vehicle (collisions, traffic accidents, violations of the Agreement and Terms, etc.).The Lessor does not have technical attorney to delete data after each Vehicle use, that’s why some of Vehicle surroundings records can be available to future Lessee’s who use the particular Vehicle. Records are stored for up to 60 days, depending on the capacity of the Vehicle memory space.
						</td>
						<td>
							Article 6 (1)(f) of the GDPR – legitimate interests: to protect the Vehicle, to investiaget violations related to the and the Lessor’s legal rights, as well as to ensure compliance with the Agreement and Terms.
						</td>
					</tr>
					<tr>
						<td>Geographical location and the vehicle speed</td>
						<td>
							The Lessor retains the right to determine the geographical location of the car if there are objective facts pointing to an emergency situation or a serious infringement of the Terms of the Agreement (in particular, theft, vandalism, leaving the Home territory specified in the Agreement) as well as the right to verify the facts and initiate support or other counter-measures. In case of a serious infringement of the Agreement, continuously there can be checked the Car’s location and its speed measured, in particular, if the Car leaves the Home territory specified in the Agreement (Paragraph 2.15 of the Agreement), or if the maximum lease period is exceeded and no account settlement is made (Paragraph 3.1 of the Agreement).
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: identification, verification and retention of data on potential infringements or emergency situations and initiation of support or other counter-measures to ensure the protection of the Lessor’s interests as well as protection of the Lessee’s health.
						</td>
					</tr>
					<tr>
						<td>
							Information and communication with the Lessee by using an e-mail or postal address
						</td>
						<td>
							In addition, the Lessor uses the e-mail or the postal address provided by the Lessee to inform them in accordance with the applicable legislation of the Republic of Latvia of changes in products and Services under the Agreement as well as to send other information specified in the Agreement to the Lessee.
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: informing the Lessee of changes in services or sending other important notifications to the customer; improvement of service quality
						</td>
					</tr>
					<tr>
						<td>Transfer of personal data to third parties</td>
						<td>
							If third party services are used under the lease or registration relationship, the Lessor is entitled to transfer the Lessee’s personal data to the relevant third parties to the extent required for the performance of the Agreement.
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: ensuring efficiency of the Lessor’s operations through involving third party service providers; or Article 6(1)(b) of the GDPR where it is directly related to ensuring the performance of the Agreement.
						</td>
					</tr>
					<tr>
						<td>Transfer of personal data to public authorities</td>
						<td>
							The Lessor may transfer the Lessee’s personal data to public authorities that carry out administrative or criminal proceedings to the extent specified in the laws of the Republic of Latvia.
						</td>
						<td>Article 6(1)(c) of the GDPR</td>
					</tr>
					<tr>
						<td>
							Transfer of personal data to third parties in case of unauthorised parking
						</td>
						<td>
							In case of unauthorised parking on private property, the Lessor retains the right to transfer information required for communication with the respective Lessee (name, surname, address) to third parties to enable them to address the Lessee directly if the claims regarding unauthorised parking in a private territory filed by these third parties against the Lessor are clearly justified.
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: protection of the Lessor against justified third party claims in case of the infringement of private property rights.
						</td>
					</tr>
					<tr>
						<td>
							Verification of a person’s identity for fraud prevention purposes
						</td>
						<td>
							For fraud prevention purposes the Lessor may transfer the registration data provided by the Lessee (name, surname, street name, street number, postcode, city and date of birth) to credit reporting agencies. The purpose is to verify the registration of the Lessee and the possibility to contact the Lessee at the given address. The data can be transferred either during the initial registration time or upon changing the name or address. The Lessor also retains the right to carry out a repeated verification of the address in case of a well-founded suspicion concerning the reliability of the personal data. The Lessor retains the right to reject activation of the Lessee’s account if the verification of the address is found to be negative.
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: protection of the Lessor against fraud or misuse of services; assessment of the customer’s solvency
						</td>
					</tr>
					<tr>
						<td>Online verification of the driving licence and user</td>
						<td>
							The images made by the Lessee of their driving licence and the profile information created by the Lessee themselves, including the image of the person, will be processed for the purpose of the verification of the user’s identity and the driving licence and for comparison with the database the Road Traffic Safety Directorate. The data required for the examination of the driving licence are selected, the images are compared (the faces correspond) and the obtained images are stored as a continual record of the verification of the driving licence.
						</td>
						<td>
							<p>Article 6(1)(b) of the GDPR</p>
							<p>
								Article 6(1)(f) of the GDPR – legitimate interests: protection of the Lessor against fraud or misuse of services
							</p>
						</td>
					</tr>
					<tr>
						<td>Improvement of customer service quality</td>
						<td>
							The Lessor also uses the Lessee’s data for improving service quality. For example, the Lessor retains the applications received at the customer service centre (e.g., by phone, e-mail, letter or social media) together with the relevant replies as well as other profile data of the Lessee’s. In this way, the Lessor can respond better to the applications and suggestions for changes as well as inform the respective Lessee, e.g., via e-mail, as soon as the chosen function or Service is available. The Lessor also uses the phone numbers and e-mail addresses registered in the Mobile app to identify the Lessees when they communicate with the customer service staff. Thus, the Lessor can process the customer applications more efficiently.
						</td>
						<td>
							Article 6(1)(f) of the GDPR – legitimate interests: improvement and enhancement of customer service quality; provision of efficient application processing and ensuring communication
						</td>
					</tr>
					<tr>
						<td>Other use of personal data</td>
						<td>
							Personal data may also be processed in other cases which cannot be clearly defined beforehand; however, such processing would only be performed for purposes compatible with the existing data processing purposes.
						</td>
						<td>
							In most cases, data processing would be carried out on the basis of Article 6(1)(f) of the GDPR for such purposes which are compatible with the existing data processing purposes, taking into consideration Article 6(4) of the GDPR (e.g., protection of the Lessor’s interests at courts or law enforcement institutions, ensuring the fulfilment of payment liabilities, etc.)
						</td>
					</tr>
				</table>
			</div>

			<h2>
				2) What is the period of the Lessor storing the Lessee’s personal data?
			</h2>
			<p>
				All the Lessee’s information, including personal data within the Lessor’s account and information on the use of the Vehicle, is kept until the Lessee’s account is no longer active, data is deleted at the request of the Lessee’s or when the data retention period as specified in the legislation of the Republic of Latvia expires (e.g., according to accounting legislation, supporting documents must be kept for no less than 5 years from the date of the Agreement).
			</p>

			<h2>
				3) Will the Lessor transfer the Lessee’s personal data to third parties?
			</h2>
			<p>
				The Lessor is entitled to transfer the Lessee’s personal data to State authorities and other parties in case of unauthorised parking of the car. The Lessor may transfer the Lessee’s personal data to third party service providers. The service providers have the responsibility of processing the personal data themselves in accordance with the applicable legislation and their internal standards.
			</p>
			<p>
				Tesla, as a vehicle manufacturer, in exceptional cases can also access data of Lessees obtained during video surveillance, however, the manufacturer does not receive any information about the user of the vehicles, so it is not possible to associate the data of the video recordings with the identity of the Lessee or the user account. For more information on how Tesla processes personal data, see this privacy policy
			</p>

			<h2>
				4) Will the Lessor send the Lessee’s personal data to another country?
			</h2>
			<p>
				If any of the service providers used by the Lessor are located outside the EU/EEA, personal data is transferred in accordance with the requirements set out in the GDPR, including:
			</p>
			<ul>
				<li>by concluding standard clauses of the EU agreement or based on other relevant justifications for data transfer as stipulated by current regulations on personal data protection, such as codes of conduct and certification</li>
				<li>in cooperation with service providers located in a country that ensures an adequate level of data protection in accordance with the GDPR and regulations on personal data protection</li>
			</ul>

			<h2>
				5) Will the Lessee be subject to profiling or automated decision-making?
			</h2>
			<p>
				The Lessor does not perform any automated decision making or profiling.
			</p>

			<h2>
				6) What are the rights of the Lessee?
			</h2>
			<p>
				It is essential for the Lessee to understand that the Lessor processes the Lessee’s personal data and that the Lessor wishes the Lessee to be satisfied with the way it is done. Even where the Lessor does not need the Lessee’s consent for processing their personal data, the Lessee has various rights regarding their personal data processing.
			</p>

			<div className="overflow">
				<table>
					<tr>
						<th>Lessee’s rights</th>
						<th>What does it mean?</th>
					</tr>
					<tr>
						<td>Right of access</td>
						<td>
							The Lessee is entitled to obtain information as to the manner in which the Lessor processes their personal data, including the following:

							<ul>
								<li>the categories of personal data processed and the purposes of processing</li>
								<li>the legal basis for processing the personal data concerned</li>
								<li>the period of storing the personal data</li>
								<li>the recipients of the disclosed personal data</li>
								<li>if the Lessee’s personal data is sent to a country outside the EEA ,the measures of the personal data protection</li>
								<li>the existence of automated decision-making or profiling in the processing</li>
								<li>the rights of the Lessee</li>
								<li>the procedure for submitting a claim or exercise of the rights of the Lessee</li>
							</ul>

							<p>
								All the above information is also available in this Privacy policy.
							</p>
							<p>
								The Lessee is also entitled to obtain a copy of the personal data undergoing processing.
							</p>
						</td>
					</tr>
					<tr>
						<td>Right to rectification</td>
						<td>
							It is essential for the Lessor to have correct information regarding the Lessee. Therefore, the Lessor requests to be informed where any of the Lessee’s personal data are not correct, e.g., the Lessee has changed their surname or place of residence. At the Lessee’s request, the Lessor shall rectify any incorrect information without delay.
						</td>
					</tr>

					<tr>
						<td>Right to erasure (‘right to be forgotten’)</td>
						<td>
							If the Lessor processes the Lessee’s personal data in an unlawful manner, e.g., if the Lessor processes the Lessee’s personal data longer than necessary or without a legitimate reason, the Lessee shall be entitled to ask the Lessor to erase the respective information immediately.
						</td>
					</tr>
					<tr>
						<td>Right to restriction of processing</td>
						<td>
							<p>
								From the moment when the Lessee has asked the Lessor to rectify their personal data or objected to their processing and until the Lessor is able to investigate the issue and confirm the accuracy of the Lessee’s personal data (or modify them according to the Lessee’s instructions) the Lessee has the right to request restricted processing. This means that the Lessor may only process (with the exception of personal data storage) the Lessee’s personal data with the Lessee’s consent or if required with regard to legal actions for protecting the rights of another person or for important reasons of public interest.
							</p>
							<p>
								The Lessee also is entitled to the restriction of processing where the processing is unlawful and the Lessee opposes the erasure of the personal data.
							</p>
						</td>
					</tr>
					<tr>
						<td>Right to data portability</td>
						<td>
							The Lessee may be entitled to receive the personal data provided for processing, based on their consent or on the performance of a contract, in a structured, commonly used and machine-readable format. The Lessee shall have the right to ask the Lessor to transfer the data to another data controller.
						</td>
					</tr>
					<tr>
						<td>The right to withdraw the consent and object</td>
						<td>
							Currently, the Lessor is not processing personal data on the basis of consent. If in the future the Lessor processes personal data on the basis of consent, the withdrawal of consent can be sent by mail to the following address: SIA SLYFOX, Meža prospekts 15, Jūrmala, LV- 2010, or by e-mail to <a href='mailto:info@carguru.lv'>info@carguru.lv</a>. At any time the Lessee can object to the further use of their e-mail address for advertising and marketing purposes by sending a notification to the above mentioned addresses. The withdrawal or objection does not result in any other costs apart from postage expense to be paid by the Lessee themselves. If the Lessee withdraws their consent, the Lessor cannot inform the Lessee of news, advertisements and special offers further; and at the same time, consent to receiving news is also withdrawn.
						</td>
					</tr>
				</table>
			</div>

			<p>
				The Lessor will notify the Lessee's request(s) also to its service providers, to whom the Lessor will have transferred the Lessee's personal data.
			</p>

			<h2>
				7) Procedure for lodging a complaint on the use of the Lessee’s data or for exercising the rights of the Lessee
			</h2>
			<p>
				The Lessor asks to solve issues regarding the processing of personal data by first turning to the Lessor itself via mail by writing to SIA SLYFOX, Meža prospekts 15, Jūrmala, LV-2010, or via e-mail <a href='mailto:info@carguru.lv'>info@carguru.lv</a>. In accordance with Article 4(1) of the Personal Data Processing Law and Article 57(1)(f) of the GDPR, the Lessee shall have the right to lodge a complaint to the Data State Inspectorate by depositing the complaint in the post box of the Data State Inspectorate at Blaumaņa iela 11/13, Riga, Floor 1, by sending it electronically (signed with a secure electronic signature according to Article 1(2) of the Electronic Documents Law) to the e-mail address at info@dvi.gov.lv or by sending it by post to Blaumaņa iela 11/13-15, Rīga, LV-1011.
			</p>

			<h2>8) Is the Lessee’s data safe with the Lessor?</h2>
			<p>
				The Lessor shall use technical and organisational safeguard measures to protect the managed data from their distortion, loss, destruction or unauthorised access. The Lessor shall constantly improve their safeguard measures in line with technological development.
			</p>

			<h2>9) Revision</h2>
			<p>
				The Lessor shall retain the right to make changes in this Data Processing Statement and inform the Lessee of any amendments via e-mail and/or Mobile app. The revised version will be published in the Mobile app. Before each booking the Lessee should check whether there is a revised version available.
			</p>

			<br />
			<h2>Cookies</h2>

			<h3>What is Cookies?</h3>
			<p>
				A cookie is a small text file stored on your computer or mobile device and retrieved from there on subsequent visits.
			</p>

			<h3>What kinds of Cookies we use and why?</h3>
			<p>
				This website is using Cookies, including Cookies that are necessary for the website to operate correctly. Considering that the website will not work if it is not using Cookies, consent is not required to use these (necessary) Cookies.
			</p>
			<p>
				Additional to necessary Cookies, if you have given your consent, we can use analytical Cookies that collect the information about how the website is being used, find the most frequently visited sections, including the content selected by users when browsing the site, for example, when <a
					href='https://carguru.lv/'
					target="_blank"
					rel="noopener noreferrer"
				>www.carguru.lv</a> was last visited and what kind of information the user searched.
			</p>
			<p>
				This information is being used for analytical purposes in an aggregated form to understand what interests users, improve the functionality of the website, and make it easier to use.
			</p>
			<p>
				Information from Cookies is not used to identify users personally. These Cookies are used for the sole purpose stated above.
			</p>
			<p>
				A user does not have to enable these (optional) Cookies to use the website, but allowing them provides a better browsing quality. It is possible to block or delete these cookies, but in that case, some features of the website might not work to their full capacity.
			</p>
			<p>
				Additionally, we would like to mention that this website is using “Google Analytics”, a web analytics service provided by “Google, Inc” (“Google”). The information generated by these Cookies about using this website (including the user’s IP address) will be transmitted to and stored by Google on their servers. “Google” will use this information to evaluate the use of the website, to collect notifications about the work of the website for website’s managers, and to provide additional services related to the work of the website and the use of the Internet. “Google” can send this information to third parties, when required by law or to the extent that third parties process this data on “Google” behalf. “Google” will never relate your IP address to other data that is in their possession.
			</p>
			<p>
				Using this website, you permit “Google” for your data processing with the exact purpose and in the precise way described above. For more information about “Google Analytics” Cookies, visit this <a
					href='https://policies.google.com/technologies/cookies'
					target="_blank"
					rel="noopener noreferrer"
				>link</a>.
			</p>

			<h3>How to control cookies and to stop receiving them?</h3>
			<p>
				It is optional to control and delete Cookies. You can find more information on how to reject the use of Cookies and change browser settings on the <a
					href='https://www.aboutcookies.org'
					target="_blank"
					rel="noopener noreferrer"
				>https://www.aboutcookies.org</a> website.
			</p>
			<p>
				You can delete all cookies from your computer, and the majority of browsers can be set in a way that blocks saving Cookies to your computer. But in this case, you will have to manually adjust some settings each time you are visiting the website, after which some features of the website might not work to their full capacity.
			</p>
		</div>
	)
}