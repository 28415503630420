function fallbackCopyTextToClipboard(text, result) {
	var textArea = document.createElement('textarea')
	textArea.value = text

	// Avoid scrolling to bottom
	textArea.style.top = '0'
	textArea.style.left = '0'
	textArea.style.position = 'fixed'

	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		var successful = document.execCommand('copy')
		var msg = successful ? 'successful' : 'unsuccessful'
		result('success')
		console.log('Fallback: Copying text command was ' + msg)
	} catch (err) {
		result('error')
		console.error('Fallback: Oops, unable to copy', err)
	}

	document.body.removeChild(textArea)
}

export function copyTextToClipboard(text, result) {
	if (!navigator.clipboard) {
		return fallbackCopyTextToClipboard(text, result)
	}

	navigator.clipboard.writeText(text).then(
		function () {
			result('success')
			console.log('Async: Copying to clipboard was successful!')
		},
		function (err) {
			result('error')
			console.error('Async: Could not copy text: ', err)
		}
	)
}
