import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Promo from './components/Promo/Promo';
import Features from './components/Features/Features';
import Slider from './components/Slider/Slider';
import Document from './components/Document/Document';

import './business.css';


const Business = () => {

  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("nav.business")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return (
    <>
      <Promo />
      <Features />
      <Slider />
      <Document />
    </>
  )
}
export default Business;