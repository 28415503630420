import React from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './buttons.sass'


const Buttons = ({ onReg = false, onLog = false, onDark = false }) => {
  const { t } = useTranslation()
  const language = useSelector(state => state.language)

  return (
    <>
      {(!onReg && !onLog) ? (
        <>
          <div className="button button-app button-ios">
            <a
              href="//itunes.apple.com/lv/app/carguru-car-sharing/id1229147815?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="button-img" src={`/img/buttons/${language}/${onDark ? 'apple-dark' : 'apple'}.svg`} alt="App Store" />
            </a>
          </div>
          <div className="button button-app button-android">
            <a
              href="//play.google.com/store/apps/details?id=lv.slyfox.carguru"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="button-img" src={`/img/buttons/${language}/${onDark ? 'google-dark' : 'google'}.svg`} alt="Google Play" />
            </a>
          </div>
        </>
      ) : (
        <>
          {onReg && (
            <div className={`${onDark ? 'button button-dark' : 'button'}`}>
              <a href={`https://lk.carguru.lv/${language}/auth/sign-up`}>
                {t("button.registration")}
              </a>
            </div>
          )}

          {onLog && (
            <div className="button button-dark">
              <a href={`https://lk.carguru.lv/${language}/auth/sign-in`}>
                {t("button.login")}
              </a>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Buttons