export const PrivacyLV = () => {
	return (
		<div className='legal-privacy'>
			<h1>Datu apstrādes paziņojums</h1>

			<p>
				SLYFOX SIA (turpmāk arī – Iznomātājs) nopietni attiecas pret lietotāju (turpmāk arī – Nomnieks) un citu personu datu privātumu. Datu privātums un lietotāju privātās dzīves aizsardzība personas datu apstrādē ir būtisks jautājums, kuru SLYFOX SIA ņem vērā savos biznesa procesos.
			</p>

			<p>
				SLYFOX SIA apstrādā lietotāja personas datus, tikai balstoties uz piemērojamajiem datu aizsardzības tiesību aktiem, jo īpaši Eiropas Savienības Vispārīgo datu aizsardzības regulu 2016/679 (turpmāk – VDAR), kā arī saistošajiem Latvijas Republikas tiesību aktiem, tas ir, Fizisko personu datu apstrādes likumu un saistītajiem Ministru kabineta noteikumiem. Šajā politikā lietotie termini saskan ar Līgumā lietotajiem terminiem.
			</p>

			<h2>
				1) Kādus personas datus par Nomnieku Iznomātājs apstrādā un kādiem mērķiem?
			</h2>

			<div className="overflow">
				<table>
					<tr>
						<th>Datu apstrādes veids un kategorijas</th>
						<th>Ko tas nozīmē?</th>
						<th>Tiesiskais pamats</th>
					</tr>
					<tr>
						<td>
							Nomnieka identifikācijas un lietotāja konta reģistrācijai nepieciešamie dati
						</td>
						<td>
							<p>
								Lai Iznomātājs varētu noslēgt līgumu ar Nomnieku, Iznomātajam nepieciešams saņemt personas datus, kas identificē lietotāju, tajā skaitā vārdu, uzvārdu, dzimšanas datumu.
							</p>
							<p>
								Šie dati un Iznomātāja kontaktinformācija (tālrunis, e-pasta vai pasta adrese) tiek izmantota arī, lai izveidotu Nomnieka individuālo kontu.
							</p>
							<p>
								Gadījumā, ja Nomnieks ir juridiska persona, attiecīgā informācija būs nepieciešama par juridiskās personas pārstāvi vai kontaktpersonu.
							</p>
						</td>
						<td>
							<p>VDAR 6. panta pirmās daļas (b) apakšpunkts</p>
							<p>
								VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: Iznomātāja aizsardzība pret krāpšanas vai ļaunprātīgas pakalpojumu izmantošanas gadījumiem, nomas un citu procesu pilnveide, pakalpojumu kvalitātes uzlabošana
							</p>
						</td>
					</tr>
					<tr>
						<td>
							Automašīnu lietojums - izbraukšanas vieta un galamērķis, izbraukšanas un ierašanās galamērķī laiks, izmantošanas veids un laiks, kā arī nobrauktais attālums kilometros
						</td>
						<td>
							<p>
								Par Nomnieka veiktajiem nomas procesiem tiek vākti un uzglabāti dati par izbraukšanas vietu un galamērķi, izbraukšanas un ierašanās galamērķī laiku, izmantošanas veidu un laiku, kā arī nobraukto attālumu kilometros, kamēr Nomniekam ir aktīvs Lietotāja konts. Šos datus Nomnieks var jebkurā laikā aplūkot Mobilajā lietotnē. Minēto datu izmantošana, tiek veikta tādā apjomā, kāds nepieciešams līgumisko attiecību ar Nomnieku izpildei. Minētie dati tiek izmantoti par pamatu maksājumu noteikšanai.
							</p>
							<p>
								Papildus tam, minētie dati tiek izmantoti arī, lai atklātu, labotu kļūdas un nepilnības nomas procesā un vispārējā darbībā, pēc iespējas izmantojot
								anonimizētus datus.
							</p>
						</td>
						<td>

							<p>VDAR 6. panta pirmās daļas (b) apakšpunkts</p>
							<p>
								VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: pilnveidot nomas un citus procesus, uzlabot pakalpojumu kvalitāti
							</p>
						</td>
					</tr>
					<tr>
						<td>Automašīnas apkārtnes videoieraksti (attiecas tikai uz Tesla markas Automašīnām)</td>
						<td>
							Tesla Automašīnas pēc noklusējuma ir aprīkotas ar videonovērošanu, kas uzrauga Automašīnas ārpusi, lai nodrošnātu tās drošību un ļautu izmantot tās funkcijas, piemēram autopilota vai luksoforu un ceļa zīmju atpazīšanas funkciju. Šo funkciju Iznomātājs nevar atspējot vai konfigurēt. Šīs uzraudzības laikā daļa datu tiek saglabāti Automašīnas diskā, kas Iznomātājam un Nomniekiem ir pieejami izmantojot skārienekrānu. Iznomātājs var izmantot videoierakstus ja tie ir nepieciešami, lai pierādītu un izmeklētu jebkādus Automašīnas bojājumus (sadursmes, ceļu satiksmes negadījumus, Līguma un Noteikumu pārkāpumus utt.)Iznomātājam nav tehnisku pilnvaru dzēst datus pēc katras nākamās Automašīnas izmantošanas, tāpēc daži no Automašīnas apkārtnes videoierakstiem var būt pieejami nākamajiem Nomniekiem, kas izmanto konkrēto Automašīnu. Ieraksti tiek glabāti līdz 60 dienām atkarībā no Automašīnas atmiņas vietas ietilpības.
						</td>
						<td>
							VDAR 6.panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: aizsargāt Automašīnas, izmeklēt ar tām saistītus pārkāpumus un Iznomātāja likumīgās tiesības, kā arī lai nodrošinātu atbilstīnu Līgumam un Noteikumiem
						</td>
					</tr>
					<tr>
						<td>Automašīnas ģeogrāfiskā atrašanās vieta un ātrums</td>
						<td>
							Iznomātājs patur tiesības noteikt Automašīnas ģeogrāfisko atrašanās vietu, ja pastāv objektīvi fakti, kas norāda uz ārkārtas situāciju vai smagu Līguma un Noteikumu pārkāpumu (jo īpaši zādzību, vandālismu, Līgumā noteiktās Mājas teritorijas atstāšanu), kā arī tiesības pārbaudīt šos faktus un iniciētu atbalsta vai citus pretpasākumus. Smaga šī Līguma pārkāpuma gadījumā var tikt veikta nepārtraukta ģeogrāfiskās atrašanās vietas un ātruma noteikšana, jo īpaši gadījumos, kad Automašīna atstāj Līgumā noteikto izmantošanas Mājas teritoriju (Līguma 2.15. punkts), vai tiek pārkāpts maksimālais nomas periods un netiek veikts norēķins (Līguma 3.1. punkts).
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: konstatēt, pārbaudīt un saglabāt ziņas par potenciāliem pārkāpumiem vai ārkārtas situācijām un iniciēt atbalsta vai pasākumus, lai nodrošinātu iznomātāja interešu aizsardzību, kā arī nomnieka veselības aizsardzību
						</td>
					</tr>
					<tr>
						<td>
							Nomnieka informēšana un saziņa, izmantojot e- pasta vai pasta adresi
						</td>
						<td>
							Papildus Iznomātājs izmanto Nomnieka norādīto e-pasta vai pasta adresi, lai informētu saskaņā ar piemērojamajiem Latvijas Republikas tiesību aktiem par izmaiņām produktos un Pakalpojumos, kas attiecas uz Līgumu, kā arī, lai nosūtītu Nomniekam citu likumā noteikto informāciju.
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: informēšana par Iznomātāja pakalpojumu izmaiņām vai citu svarīgu paziņojumu nosūtīšana klientiem, pakalpojumu kvalitātes uzlabošana
						</td>
					</tr>
					<tr>
						<td>Personas datu nodošana trešajām personām</td>
						<td>
							Ja nomas vai reģistrācijas attiecību ietvaros tiek izmantoti trešo personu pakalpojumi, Iznomātājs ir tiesīgs nodot Nomnieka personīgos datus minētajām trešajām personām tādā apjomā, kas nepieciešams ar Nomnieka noslēgto Līguma saistību izpildei.
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts - leģitīmās intereses: iznomātāja darbības efektivitātes nodrošināšana, piesaistot pakalpojumu sniedzējus vai VDAR 6. panta pirmās daļas (b) apakšpunkts, ja tas tieši saistīts ar līguma izpildes nodrošināšanu
						</td>
					</tr>
					<tr>
						<td>Personas datu nodošana varas iestādēm</td>
						<td>
							Iznomātājs var nodot Nomnieka personīgos datus iestādēm, kas veic administratīvo vai krimināltiesisko procesu, Latvijas Republikas likumos paredzētajā apmērā.
						</td>
						<td>VDAR 6. panta pirmās daļas (c) apakšpunkts</td>
					</tr>
					<tr>
						<td>
							Personas datu nodošana citām personām neatļautas Automašīnas novietošanas gadījumos
						</td>
						<td>
							Personas datu nodošana, ja neatļauti novietota Automašīnu privātīpašumā Iznomātājs patur tiesības nodot informāciju, kas nepieciešama saziņai ar attiecīgo Nomnieku (vārds, uzvārds, adrese), trešajām personām, lai tās varētu vērsties tieši pret Nomnieku, ja trešo Personu pret Iznomātāju iesniegtie prasījumi par Automašīnas novietošanas pārkāpumiem privātā teritorijā ir acīmredzami pamatoti.
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: Iznomātāja aizsardzība pret trešo pušu prasībām pamatotos privātīpašuma aizskāruma gadījumos.
						</td>
					</tr>
					<tr>
						<td>
							Personas identitātes pārbaude krāpšanas novēršanas nolūkos
						</td>
						<td>
							Krāpšanas novēršanas nolūkos Iznomātājs var Nomnieka sniegtos reģistrācijas datus (uzvārds, vārds, iela, ēkas numurs, pasta indekss, pilsēta un dzimšanas datums) nodot kredītu uzraudzības iestādēm. Tas tiek darīts nolūkā, lai pārbaudītu Nomnieka reģistrāciju un, vai ar Nomnieku var sazināties norādītajā adresē. Nodošana var tikt veikta gan sākotnējās reģistrācijas laikā, gan arī mainot vārdu vai adresi. Turklāt Iznomātājs patur tiesības veikt atkārtotu adreses pārbaudi, ja pastāv pamatotas aizdomas par Nomnieka personas datu ticamību. Iznomātājs patur tiesības atteikt Nomnieka konta aktivizēšanu, ja adreses pārbaudes
							rezultāti ir negatīvi.
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: Iznomātāja aizsardzība pret krāpšanas vai ļaunprātīgas pakalpojumu izmantošanas gadījumiem, klientu maksātspējas novērtēšana
						</td>
					</tr>
					<tr>
						<td>Vadītāja apliecības pārbaude un lietotāja verifikācija tiešsaistē</td>
						<td>
							Nomnieka no savas vadītāja apliecības veidotie attēli un Nomnieka paša veidotā profila informācija, tajā skaitā personas attēls, tiks apstrādāta lietotāja identitātes un vadītāja apliecības pārbaudes nolūkos un salīdzināšanai ar Ceļu satiksmes drošības direkcijas datu bāzi. Tiek atlasīti vadītāja apliecības pārbaudei nepieciešami dati, salīdzināti attēli (sejas atbilstība) un iegūtie attēli tiek saglabāti kā pastāvīgs vadītāja apliecības pārbaudes ieraksts.
						</td>
						<td>
							<p>VDAR 6. panta pirmās daļas (b) apakšpunkts</p>
							<p>
								VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: Iznomātāja aizsardzība pret krāpšanas vai ļaunprātīgas pakalpojumu izmantošanas gadījumiem
							</p>
						</td>
					</tr>
					<tr>
						<td>Klientu apkalpošanas uzlabošana</td>
						<td>
							Iznomātājs izmanto Nomnieka datus arī klientu apkalpošanas kvalitātes uzlabošanas nolūkiem. Piemēram, Iznomātājs saglabā klientu apkalpošanas servisā saņemtos pieprasījumus (piemēram, pa tālruni, e-pastu, vēstulē vai sociālajos mēdijos), un attiecīgās atbildes, kā arī citus Nomnieka profila datus. Tādējādi Iznomātājs var labāk atbildēt uz pieprasījumiem un ierosinājumiem izmaiņām, kā arī informēt attiecīgo Nomnieku, piemēram, pa e-pastu, tiklīdz izvēlētā funkcija vai Pakalpojums ir pieejams. Papildus tam, Iznomātājs izmanto arī Mobilajā lietotnē reģistrētos tālruņa numurus un e-pasta adreses, lai identificētu Nomniekus saziņā ar klientu apkalpošanas darbiniekiem. Tādējādi. Iznomātājs var efektīvāk apstrādāt klientu pieprasījumus
						</td>
						<td>
							VDAR 6. panta pirmās daļas (f) apakšpunkts – leģitīmās intereses: klientu servisa uzlabošana un pilnveidošana, efektīva pieprasījumu apstrādes un komunikācijas nodrošināšana
						</td>
					</tr>
					<tr>
						<td>Cita personas datu izmantošana</td>
						<td>
							Personas dati var tik apstrādāti arī citos gadījumos, kurus nav iespējams konkrēti definēt iepriekš, bet šāda apstrāde būtu tikai tādos nolūkos, kas ir saderīgi ar esošajiem datu apstrādes nolūkiem.
						</td>
						<td>
							Vairumā gadījumu apstrāde tiktu veikta uz VDAR 6. panta pirmās daļas (f) apakšpunkta pamata tādos nolūkos, kas ir saderīgi ar esošajiem datu apstrādes nolūkiem, ņemot vērā VDAR 6. panta ceturto daļu (piemēram, Iznomātāja interešu aizsardzība tiesās vai tiesībsargājošās iestādēs, maksājumu saistību izpildes nodrošināšana u.c. nolūki)
						</td>
					</tr>
				</table>
			</div>

			<h2>
				2) Cik ilgi Iznomātājs uzglabā Nomnieka personas datus?
			</h2>
			<p>
				Visa ar Nomnieku saistītā informācija, tajā skaitā Lietotāja kontā esošie personas dati un informācija par Automašīnas izmantošanu, tiek glabāta līdz brīdim, kad Lietotāja konts vairs nav aktīvs, dati tiek dzēsti pēc Nomnieka pieprasījuma vai iestājas Latvijas Republikas tiesību aktos noteiktais datu glabāšanas termiņš (piemēram, grāmatvedību regulējošie normatīvie akti nosaka pienākumu glabāt attaisnojuma dokumentus ne mazāk kā 5 gadus no Līguma noslēgšanas brīža).
			</p>

			<h2>
				3) Vai Iznomātājs nodos Nomnieka personas datus citām personām?
			</h2>
			<p>
				Iznomātājam ir tiesības nodot Nomnieka personas datus varas iestādēm un citām personām neatļautas Automašīnas novietošanas gadījumos. Iznomātājs var nodot Nomnieka datus trešo personu pakalpojumu sniedzējiem. Šo pakalpojumu sniedzēju pienākums ir pašiem apstrādāt personas datus saskaņā ar normatīvajiem aktiem un saskaņā ar šo personu iekšējiem standartiem.
			</p>
			<p>
				Tesla kā transportlīdzekļu ražotājs izņēmuma gadījumos var piekļūt arī Nomnieku datiem, kas iegūti videonovērošanas laikā, tomēr ražotājs nesaņem nekādu informāciju par transportlīdzekļu lietotāju, tāpēc nevar saistīt videoierakstu datus ar Nomnieka identitāti vai lietotāja kontu. Vairāk informācijas par to, kā Tesla apstrādā personas datus, skatīt šajā konfidencialitātes politikā.
			</p>

			<h2>
				4) Vai Nomnieka personas datus nosūtīs uz citu valsti?
			</h2>
			<p>
				Ja kāds no Iznomātāja izmantotajiem pakalpojumu sniedzējiem atrodas ārpus ES/EEZ personas dati tiek nodoti saskaņā ar prasībām, kas ir noteiktas VDAR, tostarp:
			</p>
			<ul>
				<li>noslēdzot ES līguma standartklauzulas vai pamatojoties uz citiem atbilstošiem pamatojumiem datu nosūtīšanai, ko nosaka spēkā esošie normatīvie akti par personas datu aizsardzību, piemēram rīcības kodeksi un sertifikācija</li>
				<li>sadarbojoties ar pakalpojumu sniedzējiem, kuri atrodas valstī, kas nodrošina pietiekamu datu aizsardzības līmeni saskaņā ar VDAR un normatīvajiem aktiem par personas datu aizsardzību</li>
			</ul>

			<h2>
				5) Vai Nomnieks tiks pakļauts profilēšanai vai automatizētai lēmumu pieņemšanai?
			</h2>
			<p>
				Iznomātāja darbībā netiek veikta automatizēta lēmumu pieņemšana vai profilēšana.
			</p>

			<h2>
				6) Kādas ir Nomnieka tiesības?
			</h2>
			<p>
				Ir būtiski, lai Nomnieks izprastu, ka Iznomātājs apstrādā Nomnieka personas datus un Iznomātājs vēlas, lai Nomnieku apmierina tas, kā Iznomātājs to dara. Pat ja Iznomātājam nav nepieciešama Nomnieka atļauja, lai apstrādātu Nomnieka personas datus, Nomniekam ir dažādas tiesības attiecībā uz savu personas datu apstrādi.
			</p>

			<div className="overflow">
				<table>
					<tr>
						<th>Nomnieka tiesības</th>
						<th>Ko tas nozīmē?</th>
					</tr>
					<tr>
						<td>Piekļuves tiesības</td>
						<td>
							Nomniekam ir tiesības saņemt informāciju par to, kādā veidā Iznomātājs apstrādā Nomnieka personas datus, to starpā informāciju par to:

							<ul>
								<li>Kādas personas datu kategorijas Iznomātājs apstrādā un kādā nolūkā</li>
								<li>Kāds ir tiesiskais pamats Nomnieka personas datu apstrādei</li>
								<li>Cik ilgi Iznomātājs uzglabā Nomnieka personas datus</li>
								<li>Kam Iznomātājs izpauž Nomnieka personas datus</li>
								<li>Ja Nomnieka personas dati tiek nosūtīti valstij ārpus EEZ robežām, kā Iznomātājs nodrošina Jūsu personas datu aizsardzību.</li>
								<li>Vai apstrādē ietilpst automatizēta lēmumu pieņemšana vai profilēšana</li>
								<li>Kādas ir Nomnieka tiesības</li>
								<li>Kā iesniegt sūdzību vai īstenot Nomnieka tiesības</li>
							</ul>

							<p>
								Visa iepriekš minētā informācija ir pieejama šajā Privātuma politikā.
							</p>
							<p>
								Nomniekam ir tiesības pieprasīt arī savu Iznomātāja apstrādē esošo personas datu kopiju.
							</p>
						</td>
					</tr>
					<tr>
						<td>Tiesības labot datus</td>
						<td>
							Ir būtiski, lai Iznomātāja rīcībā būtu pareiza informācija par Nomnieku. Tādējādi Iznomātājs lūdz informēt, ja kādi no Nomnieka personas datiem nav pareizi, piemēram, Nomnieks ir mainījis savu uzvārdu vai dzīvesvietu. Iznomātājs nepareizo informāciju pēc Nomnieka pieprasījuma nekavējoties izlabo.
						</td>
					</tr>

					<tr>
						<td>Tiesības uz dzēšanu (tiesības „tikt aizmirstam”)</td>
						<td>
							Ja Iznomātājs apstrādā Nomnieka personas datus prettiesiskā veidā, piemēram, ja Izdomātājs apstrādā Nomnieka personas datus ilgāk, nekā
							tas nepieciešams, vai bez pamatota iemesla, Nomniekam ir tiesības lūgt Iznomātāju dzēst šo informāciju.
						</td>
					</tr>
					<tr>
						<td>Tiesības ierobežot apstrādi</td>
						<td>
							<p>
								No brīža, kad Nomnieks ir pieprasījis Iznomātāju labot savus personas datus vai iebildis pret apstrādi, un kamēr Iznomātājs nebūs spējīgs izmeklēt problēmu un apstiprināt Nomnieka personas datu precizitāti (vai mainīt tos pēc Nomnieka norādījumiem), Nomniekam ir tiesības pieprasīt ierobežotu apstrādi. Tas nozīmē, ka Iznomātājs var apstrādāt (izņemot personas datu glabāšanu) Nomnieka personas datus tikai ar Nomnieka piekrišanu, vai ja tas nepieciešams saistībā ar likumīgām prasībām, lai aizsargātu kādas citas personas tiesības vai pastāv būtiskas sabiedrības intereses veikt apstrādi.
							</p>
							<p>
								Nomnieks var pieprasīt, lai Iznomātājs ierobežotu Nomnieka personas datu apstrādi arī tad, ja apstrāde ir prettiesiska, bet Nomnieks nevēlas, lai Iznomātājs dzēstu personas datus.
							</p>
						</td>
					</tr>
					<tr>
						<td>Tiesības uz datu pārnesamību</td>
						<td>
							Nomniekam var būt tiesības pieprasīt, lai Nomnieka personas dati, kurus Nomnieks ir iesniedzis apstrādei, pamatojoties uz piekrišanu vai lai izpildītu līgumu, tiktu iesniegti Nomniekam strukturētā, plaši izmantotā un mašīnlasāmā formātā. Nomniekam ir tiesības pieprasīt, lai šī informācija tiktu nosūtīta arī citam datu pārzinim.
						</td>
					</tr>
					<tr>
						<td>Tiesības atsaukt savu piekrišanu un iebilst</td>
						<td>
							Šobrīd Iznomātājs neapstrādā personas datus uz piekrišanas pamata. Gadījumā, ja Iznomātājs apstrādās personas datus uz piekrišanas pamata nākotnē, piekrišanas atsaukšana var tikt nosūtīta pa pastu uz adresi: SIA SLYFOX, Meža prospekts 15, Jūrmala, LV-2010, vai e-pastu <a href='mailto:info@carguru.lv'>info@carguru.lv</a>. Nomnieks var jebkurā laikā iebilst viņa e-pasta adreses, viņa datu turpmākai izmantošanai reklāmas nolūkos un mārketinga izpētei, par to nosūtot paziņojumu uz iepriekš minētajām adresēm. Atsaukšanas vai iebilšanas rezultātā nerodas nekādas citas izmaksas, izņemot pasta sūtīšanas izmaksas, ko Nomnieks sedz patstāvīgi. Ja Nomnieks atsauc savu piekrišanu, Iznomātājs vairs nevar informēt Nomnieku par jaunumiem, reklāmām un īpašajiem piedāvājumiem; vienlaicīgi tiek atsaukta jebkāda sniegtā piekrišana jaunumu saņemšanai.
						</td>
					</tr>
				</table>
			</div>

			<p>
				Iznomātājs paziņos par Nomnieka pieprasījumu (pieprasījumiem) arī saviem pakalpojumu sniedzējiem, kuriem Iznomātājs būs nodevis Nomnieka personas datus.
			</p>

			<h2>
				7) Kā iesniegt sūdzību par Nomnieka datu lietošanu vai īstenot Nomnieka tiesības?
			</h2>
			<p>
				Jautājumus par personas datu apstrādi Iznomātājs vispirms lūdz risināt ar pašu Iznomātāju, sazinoties pa pastu SIA SLYFOX, Meža prospekts 15, Jūrmala, LV-2010, vai e-pastu <a href='mailto:info@carguru.lv'>info@carguru.lv</a>. Saskaņā ar Fizisko personu datu apstrādes likuma 4. panta pirmo daļu un VDAR 57. panta 1. punkta (f) apakšpunktu, Nomniekam ir tiesības sūdzību iesniegt Datu valsts inspekcijā, ievietojot sūdzību Datu valsts inspekcijas pasta kastē Blaumaņa ielā 11/13, Rīgā, 1. stāvā, atsūtot elektroniski (atbilstoši Elektronisko dokumentu likuma 1. panta 2. punktam parakstītu ar drošu elektronisko parakstu) uz e-pasta adresi <a href='mailto:info@dvi.gov.lv'>info@dvi.gov.lv</a> vai nosūtot pa pastu uz adresi Blaumaņa iela 11/13-15, Rīga, LV-1011.
			</p>

			<h2>8) Vai Nomnieka dati pie Iznomātāja ir drošībā?</h2>
			<p>
				Iznomātājs izmanto tehniskos un organizatoriskos drošības pasākumus, lai aizsargātu pārvaldītos datus no to sagrozīšanas, zuduma, iznīcināšanas vai nepiederošu personu piekļuves. Iznomātājs nepārtraukti uzlabo savus drošības pasākumus, sekojot tehnoloģijas attīstībai.
			</p>

			<h2>9) Pārskatīšana</h2>
			<p>
				Iznomātājs patur tiesības veikt izmaiņas šajā Privātuma paziņojumiem un informēt Nomnieku par jebkādiem grozījumiem e-pastā un/vai Mobilajā lietotnē. Atjaunotā redakcija tiks publicēta Mobilajā lietotnē. Nomniekam pirms katras rezervācijas jāpārbauda, vai nav pieejama atjaunota redakcija.
			</p>

			<br />
			<h2>Sīkdatnes</h2>

			<h3>Kas ir sīkdatnes?</h3>
			<p>
				Sīkdatnes, angļu valodā sauktas cookies, ir neliela teksta datne, ko tīmekļa vietne saglabā jūsu datorā vai mobilajā ierīcē, kad jūs atverat vietni.
			</p>

			<h3>Kādas sīkdatnes mēs izmantojam un kādam nolūkam?</h3>
			<p>
				Šī tīmekļvietne izmanto sīkdatnes, tai skaitā sīkdatnes, kas nepieciešamas tīmekļa vietnes darbībai. Ņemot vērā, ka interneta vietne nedarbosies, ja sīkdatnes netiks izmantotas, šo (nepieciešamo) sīkdatņu izmantošanai piekrišana netiek prasīta.
			</p>
			<p>
				Papildus nepieciešamajām sīkdatnēm, ja būsiet sniedzis savu piekrišanu, mēs varam izmantot arī analītiskās sīkdatnes, kas apkopo informāciju, kā tiek lietota tīmekļa vietne, konstatē biežāk apmeklētās sadaļas, ieskaitot saturu, kuru Jūs izvēlieties, pārlūkojot vietni, piemēram, kad jūs pēdējo reizi apmeklējāt <a
					href='https://carguru.lv/'
					target="_blank"
					rel="noopener noreferrer"
				>www.carguru.lv</a> un kāda veida informāciju esat tajā meklējuši
			</p>
			<p>
				Šī informācija tiek lietota analīzes nolūkos apkopotā veidā, lai noskaidrotu, kas interesē vietnes lietotājus, un varētu uzlabot vietnes funkcionalitāti, padarīt to ērtāk lietojamu.
			</p>
			<p>
				Ar sīkdatnēm saistītā informācija netiek izmantota, lai jūs personiski identificētu. Šīs sīkdatnes tiek izmantotas tikai un vienīgi tam mērķim, kas norādīts šeit.
			</p>
			<p>
				Šīs sīkdatnes nav obligāti jāiespējo, lai šī vietne darbotos, bet tās nodrošina labāku pārlūkošanas kvalitāti. Šīs sīkdatnes ir iespējams dzēst vai bloķēt, bet tādā gadījumā atsevišķas šīs vietnes iespējas var nedarboties pienācīgi.
			</p>
			<p>
				Papildus norādām, ka šī vietne izmanto "Google Analytics", tīmekļa analītikas pakalpojumu, ko sniedz "Google, Inc" ("Google"). Informācija, ko šī sīkdatne ģenerē par Jūsu šīs vietnes lietojumu (ieskaitot Jūsu IP adresi), tiks nosūtīta uz "Google" serveriem un tiks tur glabāta. "Google" izmantos šo informāciju, lai novērtētu Jūsu vietnes lietojumu, apkopotu ziņojumus par vietnes darbībām vietnes vadītājiem un sniegtu citus pakalpojumus saistībā ar vietnes darbībām un interneta lietojumu. "Google" var nosūtīt šo informāciju trešajām personām, kad tas ir nepieciešams likuma ietvaros vai ciktāl trešās personas apstrādā šos datus "Google" vārdā. "Google" nekad nesaistīs Jūsu IP adresi ar citiem datiem Google turējumā. Izmantojot šo vietni, Jūs dodat atļauju "Google" datu apstrādei saistībā ar Jums tādā veidā un tādiem mērķiem, kas norādīti augstāk. Sīkākai informācijai par "Google Analytics" sīkdatnēm, spiediet <a
					href='https://policies.google.com/technologies/cookies'
					target="_blank"
					rel="noopener noreferrer"
				>šeit</a>.
			</p>

			<h3>Kā var kontrolēt sīkdatnes un pārtraukt to saņemšanu?</h3>
			<p>
				Pēc vēlēšanās Jūs varat sīkdatnes kontrolēt un izdzēst. Plašāka informācija par to, kā noraidīt sīkdatnes saglabāšanu un mainīt pārlūka iestatījumus, ir pieejama tīmekļa vietnē <a
					href='https://www.aboutcookies.org/'
					target="_blank"
					rel="noopener noreferrer"
				>https://www.aboutcookies.org</a>.
			</p>
			<p>
				Jūs varat izdzēst visas sīkdatnes, kuras ir jūsu datorā, un lielāko daļu pārlūkprogrammu var iestatīt tā, lai tiktu bloķēta sīkdatņu ievietošana datorā. Taču tādā gadījumā jums būs pašrocīgi jāpielāgo daži iestatījumi ikreiz, kad apmeklēsiet vietni, un varbūt nedarbosies daži pakalpojumi un funkcijas.
			</p>

		</div>
	)
}