export const useCookie = {
	get: (name) => {
		var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
		if (match) return match[2]
	},

	set: (name, value, options = {}) => {
		if (options.expires instanceof Date) options.expires = options.expires.toUTCString()

		let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

		for (let optionKey in options) {
			updatedCookie += "; " + optionKey
			let optionValue = options[optionKey]
			if (optionValue !== true) updatedCookie += "=" + optionValue
		}
		document.cookie = updatedCookie
	},

	delete: (name) => { useCookie.set(name, "", {'expires': 'Thu, 01 Jan 1970 00:00:00 GMT', 'max-age': -1 }) },

	deleteAll: () => {
		var cookies = document.cookie.split(";")

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i]
			var eqPos = cookie.indexOf("=")
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; max-age=-1"
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; max-age=-1; path=/"
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; max-age=-1; domain=.crgr.dev; path=/"
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; max-age=-1; domain=.carguru.lv; path=/"
		}
	}
}