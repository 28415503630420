import React from 'react'
import ReactDOM from 'react-dom'

import App from './App/App'
import ErrorBoundary from './components/error-boundary'
import Spinner from './components/Spinner/Spinner'

import Service from './services/service'
import ServiceContext from './services/service-context'

import TagManager from 'react-gtm-module'
// import ReactPixel from 'react-facebook-pixel'

import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import './i18nextConf'

import './style/fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot'
import './style/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf'
import './style/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff'
import './style/fonts/AvenirNextCyr/AvenirNextCyr-Light.eot'
import './style/fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf'
import './style/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff'

import './index.sass'

import store from './redux/store'
import ScrollToTop from './utils/scrollToTop'
const service = new Service()

const tagManagerArgs = {
	gtmId: 'GTM-W3N3VS2',
}
TagManager.initialize(tagManagerArgs)

// ReactPixel.init('776536280828211', {}, { debug : false, autoConfig : true });
// ReactPixel.pageView();
// ReactPixel.fbq('track', 'PageView');
// https://www.npmjs.com/package/react-facebook-pixel
// https://snyk.io/advisor/npm-package/react-facebook-pixel/example
// https://stackoverflow.com/questions/64792217/how-to-add-facebook-pixel-on-next-js-react-app
// https://youtu.be/fVGgOCFzaqQ

ReactDOM.render(
	<React.Suspense fallback={<Spinner />}>
		<Provider store={store}>
			<ErrorBoundary>
				<ServiceContext.Provider value={service}>
					<Router>
						<ScrollToTop>
							<App />
						</ScrollToTop>
					</Router>
				</ServiceContext.Provider>
			</ErrorBoundary>
		</Provider>
	</React.Suspense>,
	document.getElementById('root')
)

// <!-- Meta Pixel Code -->
// <script>
// !function(f,b,e,v,n,t,s)
// {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
// n.callMethod.apply(n,arguments):n.queue.push(arguments)};
// if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
// n.queue=[];t=b.createElement(e);t.async=!0;
// t.src=v;s=b.getElementsByTagName(e)[0];
// s.parentNode.insertBefore(t,s)}(window, document,'script',
// 'https://connect.facebook.net/en_US/fbevents.js');
// fbq('init', '776536280828211');
// fbq('track', 'PageView');
// </script>
// <noscript><img height="1" width="1" style="display:none"
// src="https://www.facebook.com/tr?id=776536280828211&ev=PageView&noscript=1"
// /></noscript>
// <!-- End Meta Pixel Code -->




