import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { handleCookies } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'

import { useCookie } from '../../utils/cookie'

import './cookie.sass'
import './animate.css'
import { Link } from 'react-router-dom'


const Cookie = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const language = useSelector(state => state.language)


  const [cookieOpened, setCookieOpened] = useState(false)

  const withoutCookies = () => dispatch(handleCookies(false))
  const withCookies = () => dispatch(handleCookies(true))

  // eslint-disable-next-line no-unused-vars
  const getLanguage = () => {
    return navigator.cookieEnabled
      ? localStorage.getItem('i18nextLng') === 'ru'
        ? 'lv'
        : localStorage.getItem('i18nextLng') || 'lv'
      : language
  }

  const cookieDecline = () => {
    localStorage.clear()
    sessionStorage.clear()
    useCookie.deleteAll()

    localStorage.setItem('cookie_consent', false)
    withoutCookies()

    setCookieOpened(false)
  }

  const cookieAccept = () => {
    useCookie.set('cookie_consent', true)
    withCookies()
    setCookieOpened(false)
  }

  useEffect(() => {
    if (
      (navigator.cookieEnabled && localStorage.getItem('cookie_consent') === 'false')
      || !navigator.cookieEnabled
    )
      withoutCookies()
    else {
      if (useCookie.get('cookie_consent')) withCookies()
      else {
        useCookie.delete('language')
        useCookie.delete('hidePromoRate')
        useCookie.delete('cookieWarn')
        localStorage.removeItem('language')

        if (!useCookie.get('cookie_consent'))
          if (localStorage.getItem('cookie_consent') === 'false')
            useCookie.deleteAll()
          else
            setTimeout(() => { setCookieOpened(true) }, 2000)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CSSTransition
      in={cookieOpened}
      timeout={3000}
      classNames="cookie-animation"
      mountOnEnter
      unmountOnExit
    >
      <section className="cookie">
        <div className="cookie-text">
          {t("cookie.info")}
          <Link to='/legal/privacy-notice'>
            {t("cookie.policy")}
          </Link>
        </div>

        <div className='cookie-btns'>
          <button
            onClick={cookieAccept}
            className="cookie-btn"
          >
            {t("cookie.accept")}
          </button>
          <button
            onClick={cookieDecline}
            className="cookie-btn white"
          >
            {t("cookie.decline")}
          </button>
        </div>
      </section>
    </CSSTransition>
  )
}

export default Cookie