import React from 'react'
import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'


const CarItem = ({ car }) => {
  const { t } = useTranslation()

  const { title, rates } = car

  const newTitle = (title === 'Volkswagen e-up!') ?
    'VW e-up! Electric' :
    (title === 'VW Passat') ? 'Volkswagen Passat' :
      (title === 'VW Passat variant') ? 'Volkswagen Passat Variant' :
        title

  return (
    <div className="cars-item">
      <HashLink to={`/rates#${title.replace(/[^a-z0-9]/gi, '')}`}>
        <div className="cars-item__text">
          <div className="cars-item__title">{newTitle}</div>
          <div className="cars-item__price">
            <div className="cars-item__price-title">{t("main.trip_from")}</div>

            <span className="cars-item__price-number">{rates[0].costDayDrivingMovement}</span>
            <div className="cars-item__price-units">
              <span className="cars-item__price-units_euro">€</span>
              <span className="cars-item__price-units_min">{t("main.min")}</span>
            </div>
          </div>
        </div>
        <div className="cars-item__img">
          <img src={`./img/cars/${title}.png`} alt={title} />
        </div>
      </HashLink>
    </div>
  )
}

export default CarItem