import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'
import { useSelector } from 'react-redux'

import Page from '../../../../components/Page/Page'

import './bonuses.sass'

const Bonuses = props => {
	const { t } = useTranslation()

	const bonuses = useSelector(state => state.bonuses)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const [sum] = useState([5, 10, 25])

	useEffect(() => {
		document.title = `${t('loyalty.bonuses-and-levels.title')} - CARGURU`
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	if (!!bonuses.length)
		return (
			<section className='bonuses'>
				<div className='container'>
					<h2 className='bonuses-title'>
						<Trans>{t('loyalty.bonuses-and-levels.title_page')}</Trans>
					</h2>

					<div className='bonuses-items'>
						{bonuses.map(bonus => (
							<div className='bonuses-item' key={bonus.title}>
								<div
									className='bonuses-item__header'
									onClick={e => {
										if (e.target.className !== 'bonuses-item__header-info')
											e.currentTarget.parentNode.classList.toggle('active')
									}}>
									<div className='bonuses-item__header-wrapper'>
										<div className='bonuses-item__header-title'>
											<img
												className='bonuses-item__header-img'
												src={`/img/loyalty/bonus/${bonus.level}.svg`}
												alt={bonus.level}
											/>
											<h3>{bonus.title}</h3>
										</div>
										<span className='bonuses-item__header-info'>
											{t(
												`loyalty.bonuses-and-levels.level_${bonus.level}.description_1`
											)}

											{+bonus.level !== 1 && (
												<>
													<span>{+bonus.amount}</span>
													{t(
														`loyalty.bonuses-and-levels.level_${bonus.level}.description_2`
													)}

													<span className='bonuses-item__header-info__button'>
														<Link
															to='details'
															smooth={true}
															offset={-20}
															duration={500}>
															{t('nav.more')}
														</Link>
													</span>
												</>
											)}
										</span>

										<div className='rates-item__arrow bonuses-item__header-arrow'>
											<svg
												width='14'
												height='8'
												viewBox='0 0 14 8'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M1 1L7 7L13 1'
													stroke='#E01254'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'></path>
											</svg>
										</div>
									</div>
								</div>

								<div className='bonuses-item__body'>
									<div className='bonuses-item__body-cars'>
										<div className='bonuses-item__cars'>
											<h4 className='bonuses-item__body-title'>
												{t('loyalty.bonuses-and-levels.title_cars')}
											</h4>
											<div className='bonuses-item__cars-items'>
												{bonus.models.map((car, index) => (
													<div className='bonuses-item__cars-item' key={index}>
														<img
															className='bonuses-item__cars-img'
															src={`https://media.carguru.online/model/${car.salt}`}
															alt={`${car.manufacturer} ${car.model}`}
														/>
														<div className='bonuses-item__cars-title'>
															{car.manufacturer} {car.model}
														</div>
														{/* <div className="bonuses-item__cars-subtitle">{(car.manufacturer === 'VW' ? 'Variant' : 'Hybrid')}</div> */}
													</div>
												))}
											</div>
										</div>
									</div>

									<div className='bonuses-item__body-info'>
										<div className='bonuses-item__info'>
											<h4 className='bonuses-item__body-title'>
												{t('loyalty.bonuses-and-levels.title_bonus')}
											</h4>

											<div className='bonuses-item__info-text'>
												<span>
													{' '}
													<span>{+bonus.percent}%</span>{' '}
													{t('loyalty.bonuses-and-levels.bonus')}
												</span>
												<div className='rates-body__info bonuses-item__info-info'>
													<div className='rates-body__tooltip bonuses-item__info-tooltip'>
														{t('loyalty.bonuses-and-levels.info_1')}
														{+bonus.percent}%
														{t('loyalty.bonuses-and-levels.info_2')}
													</div>
												</div>
											</div>
										</div>
									</div>

									{bonus.description && (
										<div className='bonuses-item__body-description'>
											{bonus.description.split('.')[0]}.
										</div>
									)}
								</div>
							</div>
						))}
					</div>

					<Page>
						<div className='bonuses-details'>
							<h3 className='bonuses-details__title' id='details'>
								{t('loyalty.bonuses-and-levels.details.title')}
							</h3>
							<span className='bonuses-details__subtitle'>
								<Trans>
									{t('loyalty.bonuses-and-levels.details.subtitle')}
								</Trans>
							</span>

							<table className='bonuses-details__table bonuses-details__table-desk'>
								<thead>
									<tr className='bonuses-details__table-row'>
										<th>{t('loyalty.bonuses-and-levels.details.table')}</th>
										<th>
											<div>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/1.svg`}
													alt='1'
												/>
												<span>
													{bonuses[0].title}
													<span> {bonuses[0].percent}%</span>
												</span>
											</div>
										</th>
										<th>
											<div>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/2.svg`}
													alt='2'
												/>
												<span>
													{bonuses[1].title}
													<span> {bonuses[1].percent}%</span>
												</span>
											</div>
										</th>
										<th>
											<div>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/3.svg`}
													alt='3'
												/>
												<span>
													{bonuses[2].title}
													<span> {bonuses[2].percent}%</span>
												</span>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className='bonuses-details__table-row'>
										<td>{sum[0]}€</td>
										<td className='bonuses-details__table-red'>
											{((sum[0] * bonuses[0].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[0] * bonuses[1].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[0] * bonuses[2].percent) / 100).toFixed(2)}
										</td>
									</tr>
									<tr className='bonuses-details__table-row'>
										<td>{sum[1]}€</td>
										<td className='bonuses-details__table-red'>
											{((sum[1] * bonuses[0].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[1] * bonuses[1].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[1] * bonuses[2].percent) / 100).toFixed(2)}
										</td>
									</tr>
									<tr className='bonuses-details__table-row'>
										<td>{sum[2]}€</td>
										<td className='bonuses-details__table-red'>
											{((sum[2] * bonuses[0].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[2] * bonuses[1].percent) / 100).toFixed(2)}
										</td>
										<td className='bonuses-details__table-red'>
											{((sum[2] * bonuses[2].percent) / 100).toFixed(2)}
										</td>
									</tr>
								</tbody>
							</table>

							<div className='bonuses-details__table-mob'>
								<div className='table-mob__items'>
									<div className='table-mob__item'>
										<div className='table-mob__title'>
											<h4>{t('loyalty.bonuses-and-levels.details.table')}</h4>
											<span>{sum[0]}€</span>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/1.svg`}
													alt='1'
												/>
												<span>
													{bonuses[0].title}
													<span> {bonuses[0].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[0] * bonuses[0].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/2.svg`}
													alt='2'
												/>
												<span>
													{bonuses[1].title}
													<span> {bonuses[1].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[0] * bonuses[1].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/3.svg`}
													alt='2'
												/>
												<span>
													{bonuses[2].title}
													<span> {bonuses[2].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[0] * bonuses[2].percent) / 100).toFixed(2)}
											</div>
										</div>
									</div>

									<div className='table-mob__item'>
										<div className='table-mob__title'>
											<h4>{t('loyalty.bonuses-and-levels.details.table')}</h4>
											<span>{sum[1]}€</span>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/1.svg`}
													alt='1'
												/>
												<span>
													{bonuses[0].title}
													<span> {bonuses[0].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[1] * bonuses[0].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/2.svg`}
													alt='2'
												/>
												<span>
													{bonuses[1].title}
													<span> {bonuses[1].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[1] * bonuses[1].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/3.svg`}
													alt='2'
												/>
												<span>
													{bonuses[2].title}
													<span> {bonuses[2].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[1] * bonuses[2].percent) / 100).toFixed(2)}
											</div>
										</div>
									</div>

									<div className='table-mob__item'>
										<div className='table-mob__title'>
											<h4>{t('loyalty.bonuses-and-levels.details.table')}</h4>
											<span>{sum[2]}€</span>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/1.svg`}
													alt='1'
												/>
												<span>
													{bonuses[0].title}
													<span> {bonuses[0].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[2] * bonuses[0].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/2.svg`}
													alt='2'
												/>
												<span>
													{bonuses[1].title}
													<span> {bonuses[1].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[2] * bonuses[1].percent) / 100).toFixed(2)}
											</div>
										</div>

										<div className='table-mob__row'>
											<div className='table-mob__row-title'>
												<img
													className='bonuses-details__table-img'
													src={`/img/loyalty/bonus/3.svg`}
													alt='2'
												/>
												<span>
													{bonuses[2].title}
													<span> {bonuses[2].percent}%</span>
												</span>
											</div>
											<div className='table-mob__row-total'>
												{((sum[2] * bonuses[2].percent) / 100).toFixed(2)}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='bonuses-details__description'>
								<Trans>
									{t('loyalty.bonuses-and-levels.details.description')}
								</Trans>
							</div>
						</div>
					</Page>
				</div>
			</section>
		)
	return true
}
export default Bonuses
