import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './about.sass';

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("nav.about")} - CARGURU`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
  
  return (
    <div className="about">
      <div className="about-description">
        {t("about.subtitle_1")}
      </div>
      <h2 className="about-title">
        <Trans>{t("main.title")}</Trans>
      </h2>

      <div className="about-features">
        <div className="about-feature">
          <svg className="about-feature__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145">
            <path fill="#1D1748" d="M97.922 55.881H79.768a1.738 1.738 0 0 0 0 3.476h18.154c.671 0 1.217.546 1.217 1.217v4.036a1.737 1.737 0 1 0 3.474 0v-4.036a4.697 4.697 0 0 0-4.691-4.693zM100.876 83.794c.961 0 1.737-.777 1.737-1.738v-6.123a5.982 5.982 0 0 0-5.974-5.977H32.566a5.307 5.307 0 0 1-5.3-5.282v-.019-.004a5.316 5.316 0 0 1 3.122-4.829 1.735 1.735 0 0 0 .868-2.298 1.736 1.736 0 0 0-2.298-.869 8.793 8.793 0 0 0-5.168 8.001v52.808c0 4.84 3.937 8.776 8.775 8.776H96.64a5.982 5.982 0 0 0 5.974-5.976v-11.096a1.736 1.736 0 1 0-3.474 0v11.096c0 1.379-1.121 2.5-2.499 2.5H32.564a5.305 5.305 0 0 1-5.298-5.301V71.645a8.726 8.726 0 0 0 5.3 1.788H96.64a2.502 2.502 0 0 1 2.499 2.5v6.123c0 .961.777 1.738 1.737 1.738z"></path>
            <path fill="#1D1748" d="M91.407 105.558h-5.983c-3.836 0-6.956-3.119-6.956-6.955s3.12-6.957 6.956-6.957H97.81c.734 0 1.331.598 1.331 1.332v19.941h3.477v-19.94a4.814 4.814 0 0 0-4.808-4.808H85.424c-5.753 0-10.432 4.68-10.432 10.432s4.679 10.432 10.432 10.432h5.983c.961 0 1.737-.778 1.737-1.738a1.737 1.737 0 0 0-1.737-1.739z"></path>
            <path fill="none" stroke="#1D1748" strokeWidth="3.685" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M35.699 86.374v-4.777M35.699 114.13v-16"></path>
            <circle fill="none" stroke="#1D1748" strokeWidth="3.685" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="85.535" cy="98.697" r="1.625"></circle>
            <path fill="none" stroke="#E11254" strokeWidth="3.685" strokeLinecap="round" strokeMiterlimit="10" d="M39.646 63.576a17.304 17.304 0 0 1-2.058-8.208c0-9.589 7.773-17.362 17.362-17.362s17.363 7.773 17.363 17.362c0 3.21-.871 6.217-2.39 8.797M78.819 47.013c8.28.284 15.222-6.198 15.506-14.478.283-8.279-6.198-15.221-14.477-15.505-8.28-.284-15.222 6.198-15.506 14.478"></path>
          </svg>
          <div className="about-feature__title"><span>1.</span> {t("about.title_1")}</div>
          <div className="about-feature__description">
            {t("about.description_1")}
          </div>
        </div>

        <div className="about-feature">
          <svg className="about-feature__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145">
            <g fill="none" strokeWidth="3.685" strokeLinecap="round" strokeMiterlimit="10">
                <circle stroke="#1D1748" cx="33.343" cy="113.652" r="9.634"></circle>
                <path stroke="#1D1748" d="M39.997 97.903c6.135 2.595 10.438 8.669 10.438 15.749 0 .966-.081 1.913-.235 2.836"></path>
                <circle stroke="#1D1748" cx="89.051" cy="113.652" r="9.634"></circle>
                <path stroke="#1D1748" d="M71.789 116.486c-.842-4.179-.142-7.698 2.16-11.188M51.444 116.486H71.11"></path>
                <path stroke="#1D1748" strokeLinejoin="round" d="M17.186 116.486h-5.792l.567-12.469s1.099-25.698 28.724-25.698H61.56c13.375 0 20.066 10.28 30.75 14.166 17.417 6.334 20.512 11.202 20.128 16.461s-3.503 7.54-3.503 7.54h-4"></path>
                <path stroke="#1D1748" d="M54.061 97.903h25.356M20.739 97.903h9.758M39.997 78.319v19.175"></path>
                <path stroke="#E11254" d="M32.892 68.838a35.745 35.745 0 0 1-.967-17.458c3.749-19.458 22.561-32.192 42.018-28.443 19.458 3.748 32.194 22.56 28.446 42.017a35.726 35.726 0 0 1-8.288 16.919"></path>
                <path stroke="#E11254" d="M42.703 65.267a22.527 22.527 0 0 1-.61-11.01 22.528 22.528 0 0 1 7.151-12.602M66.412 51.308l.123-15.164M72.941 58.822l21.345.174"></path>
                <circle stroke="#E11254" cx="66.062" cy="58.194" r="6.889"></circle>
                <circle stroke="#1D1748" strokeLinejoin="round" cx="89.051" cy="113.651" r="1.625"></circle>
                <circle stroke="#1D1748" strokeLinejoin="round" cx="33.417" cy="113.651" r="1.625"></circle>
            </g>
          </svg>
          <div className="about-feature__title"><span>2.</span> {t("about.title_2")}</div>
          <div className="about-feature__description">
            {t("about.description_2")}
          </div>
        </div>

        <div className="about-feature">
          <svg className="about-feature__img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145">
            <g fill="none" strokeWidth="3.685" strokeLinecap="round" strokeMiterlimit="10">
                <path stroke="#1D1748" d="M42.73 124.136H111M50.918 123.39V56.401M49.897 91.98l-20.92-12.992M51.141 75.683l10.474-7.423M50.197 43.808c6.955 0 12.594 5.638 12.594 12.593M70.182 71.371c8.414 0 15.235 6.821 15.235 15.235"></path>
                <path stroke="#1D1748" strokeLinejoin="round" d="M70.182 63.464c-.438 0-.872.015-1.304.039.84-2.208 1.304-4.6 1.304-7.102 0-11.037-8.948-19.984-19.984-19.984s-19.984 8.947-19.984 19.984c0 2.489.459 4.87 1.291 7.067-12.712.082-22.992 10.409-22.992 23.138 0 12.782 10.361 23.143 23.143 23.143 7.415 0 14.017-3.487 18.251-8.912.355-.454.69-.927 1.011-1.407 4.148 6.221 11.227 10.319 19.265 10.319 12.781 0 23.143-10.36 23.143-23.143-.002-12.781-10.363-23.142-23.144-23.142z"></path>
                <path stroke="#1D1748" d="M31.655 101.898c-8.446 0-15.292-6.847-15.292-15.292"></path>
                <path stroke="#E11254" strokeLinejoin="round" d="M59.146 28.809a9.142 9.142 0 0 1 5.506-1.834c2.378 0 4.537.912 6.168 2.395.936-7.146 7.034-12.667 14.436-12.667 5.114 0 9.607 2.64 12.207 6.626a11.45 11.45 0 0 1 4.694-1.008c6.354 0 11.506 5.151 11.506 11.505s-5.151 11.505-11.506 11.505a11.447 11.447 0 0 1-7.573-2.859 14.51 14.51 0 0 1-9.328 3.38c-5.011 0-9.43-2.53-12.053-6.382"></path>
            </g>
          </svg>
          <div className="about-feature__title"><span>3.</span> {t("about.title_3")}</div>
          <div className="about-feature__description">
            {t("about.description_3")}
          </div>
        </div>
      </div>

      <div className="about-description">
        {t("about.subtitle_2")}
      </div>
    </div>

  )
}

export default About;