import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {
  Switch,
  Route,
  Link,
  useLocation,
  Redirect
} from 'react-router-dom'

import GuideItem from './components/GuideItem'

import './guide.sass'


const Guide = () => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${t("nav.guide")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])


  return (
    <div className="guide">
      <Switch>

        <Route exact path='/guide'>
          <Link to='/guide/register' className="guide-block">
            <h2 className="guide-title"><span>1.</span> {t("guide.register.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>

          <Link to='/guide/start-rent' className="guide-block">
            <h2 className="guide-title"><span>2.</span> {t("guide.start-rent.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>

          <Link to='/guide/waiting-and-24h-rent' className="guide-block">
            <h2 className="guide-title"><span>3.</span> {t("guide.waiting-and-24h-rent.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>

          <Link to='/guide/finishing-trip' className="guide-block">
            <h2 className="guide-title"><span>4.</span> {t("guide.finishing-trip.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>

          <Link to='/guide/payment' className="guide-block">
            <h2 className="guide-title"><span>5.</span> {t("guide.payment.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>

          <Link to='/guide/notifications' className="guide-block">
            <h2 className="guide-title"><span>6.</span> {t("guide.notifications.title")}</h2>
            <div className="guide-arrow"></div>
          </Link>
        </Route>


        <Route path={`/guide/register`}>
          <GuideItem location={location.pathname} number={1} />
          <Redirect from="/guide/register/***" to="/guide/register" />
        </Route>

        <Route path={`/guide/start-rent`}>
          <GuideItem location={location.pathname} number={2} />
          <Redirect from="/guide/start-rent/***" to="/guide/start-rent" />
        </Route>

        <Route path={`/guide/waiting-and-24h-rent`}>
          <GuideItem location={location.pathname} number={3} />
          <Redirect from="/guide/waiting-and-24h-rent/***" to="/guide/waiting-and-24h-rent" />
        </Route>

        <Route path={`/guide/finishing-trip`}>
          <GuideItem location={location.pathname} number={4} />
          <Redirect from="/guide/finishing-trip/***" to="/guide/finishing-trip" />
        </Route>

        <Route path={`/guide/payment`}>
          <GuideItem location={location.pathname} number={5} />
          <Redirect from="/guide/payment/***" to="/guide/payment" />
        </Route>

        <Route path={`/guide/notifications`}>
          <GuideItem location={location.pathname} number={6} />
          <Redirect from="/guide/notifications/***" to="/guide/notifications" />
        </Route>

        <Redirect from="/guide/***" to="/guide" />
      </Switch>
    </div>
  )
}

export default Guide