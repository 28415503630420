import React, { useEffect } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom"
import { Trans, useTranslation } from 'react-i18next'
import WithService from '../../services/hoc'
import { useDispatch, useSelector } from 'react-redux'
import { newsRequested, dataError } from '../../redux/actions'
import ContentLoader from "react-content-loader"

import Buttons from '../Buttons/Buttons'
import LanguageSelect from '../LanguageSelect/LanguageSelect'

import facebook from './img/facebook.svg'
import instagram from './img/instagram.svg'

import './footer.sass'


const Footer = (props) => {
  const location = useLocation()
  const { t } = useTranslation()
  const news = useSelector((state) => state.news)
  const language = useSelector((state) => state.language)

  const dispatch = useDispatch()

  useEffect(() => {
    if (location.pathname !== '/news') {
      dispatch(newsRequested({ lng: language, data: [] }))

      const { Service } = props
      Service.getNews({ limit: 2, lng: language })
        .then(res => {
          dispatch(newsRequested({ lng: language, data: res.result }))
        })
        .catch((error) => {
          console.error(error)
          dispatch(dataError())
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])



  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-block">
          <div className="footer-news">
            <div className="footer-news__title">{t("footer.news")}</div>

            {(!news)
              ?
              (<>
                <div className="footer-new">
                  <ContentLoader
                    speed={0.9}
                    width={280}
                    height={40}
                    viewBox="0 0 280 40"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#b1b1b1"
                  >
                    <rect x="4" y="4" rx="3" ry="3" width="50" height="6" />
                    <rect x="5" y="18" rx="3" ry="3" width="35" height="6" />
                    <rect x="72" y="4" rx="3" ry="3" width="180" height="6" />
                    <rect x="72" y="18" rx="3" ry="3" width="145" height="6" />
                  </ContentLoader>
                </div>
                <div className="footer-new">
                  <ContentLoader
                    speed={0.9}
                    width={280}
                    height={40}
                    viewBox="0 0 280 40"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#b1b1b1"
                  >
                    <rect x="4" y="4" rx="3" ry="3" width="50" height="6" />
                    <rect x="5" y="18" rx="3" ry="3" width="35" height="6" />
                    <rect x="72" y="4" rx="3" ry="3" width="180" height="6" />
                    <rect x="72" y="18" rx="3" ry="3" width="145" height="6" />
                  </ContentLoader>
                </div>
              </>)
              :
              (<>
                {news.filter((item, i) => { return i < 2 }).map((item, index) =>
                  <div className="footer-new" key={`${item.id}_${index}`}>
                    <Link to={`/article/${item.id}`} className="footer-new__link">
                      <div className="footer-new__date">{item.date}</div>
                      <div className="footer-new__title">
                        {item.title}
                      </div>
                    </Link>
                  </div>
                )}
              </>)
            }
          </div>

          <div className="footer-info">
            <div className="footer-block__nav">
              <div className="footer-nav">
                <NavLink
                  to='/about'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.about")}
                </NavLink>
                <NavLink
                  to='/rates'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.rates")}
                </NavLink>
                <NavLink
                  to='/guide'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.guide")}
                </NavLink>
                <NavLink
                  to='/faq'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.faq")}
                </NavLink>
                <NavLink
                  to='/business'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.business")}
                </NavLink>
                <NavLink
                  to='/news'
                  activeClassName="active"
                  className="footer-nav__item"
                >
                  {t("nav.news")}
                </NavLink>
              </div>

              <LanguageSelect onDark />

            </div>

            <div className="footer-block__contacts">
              <div className="footer-contacts">
                <a href={`tel:${t("contacts.phone")}`} className="footer-contact footer-contact__phone">{t("contacts.phone")}</a>
                <a href={`mailto:${t("contacts.mail_info")}`} className="footer-contact footer-contact__mail">{t("contacts.mail_info")}</a>

                <span className="footer-contact footer-contact__address">
                  <Trans>{t("contacts.address_br")}</Trans>
                </span>

              </div>
              <div className="footer-buttons">
                <Buttons onLight />
              </div>
            </div>
          </div>
        </div>

        <div className="footer-block__remark">
          <div className="footer-remark__info">
            <Link to='/legal'>
              {t("footer.aggrement")}
            </Link>
            <Link to='/faq'>
              {t("footer.info")}
            </Link>
          </div>
          <div className="footer-remark">
            <div className="footer-remark__socials">

              <a
                href="https://www.facebook.com/carguru.lv/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-remark__social"
              >
                <img src={facebook} alt="facebook" />
              </a>

              <a
                href="https://instagram.com/carguru.lv"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-remark__social"
              >
                <img src={instagram} alt="instagram" />
              </a>
            </div>
            <span>
              Mājas lapas dizains — &nbsp;
              <a
                href="https://www.scada.lv/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-remark__author"
              >
                Scada
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default WithService()(Footer)