import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Buttons from '../../../../components/Buttons/Buttons';

import './slider.sass';
import { connect } from 'react-redux'


class Slider extends Component {

  constructor(props) {
    super(props);

    this.arrowWrapper = React.createRef()
    this.texts = React.createRef()
    this.phones = React.createRef()
  }

  componentDidMount() {
    const arrows = this.arrowWrapper.current.children;
    const texts = this.texts.current.children;
    const phones = this.phones.current.children;

    for (let i = 0; i < arrows.length; i++) {
      if (i === 0) {
        arrows[i].classList.add('active')
        arrows[i].classList.remove('hidden')
      } else {
        arrows[i].classList.add('hidden')

        texts[i].style.display = 'none'
        texts[i].style.opacity = 0

        phones[i].style.display = 'none'
        phones[i].style.opacity = 0
      }
    }
    arrows[1].classList.remove('hidden')
  }

  animate = ({ draw, duration }) => {
    const requestRef = React.createRef();
    const startTimeRef = React.createRef();

    requestAnimationFrame(function animate(time) {
      if (!startTimeRef.current)
        startTimeRef.current = time;
      let progress = (time - startTimeRef.current) / duration;
      if (progress > 1) progress = 1;

      draw(progress);

      if (progress < 1) {
        requestRef.current = requestAnimationFrame(animate);
      }
    })
  }

  render() {
    const { t } = this.props;

    let stopEvent = 0;

    const fadeOut = () => {
      const texts = this.texts.current;
      const phones = this.phones.current;

      this.animate({
        duration: 200,
        draw(progress) {
          texts.children[0].style.opacity = progress
          phones.children[0].style.opacity = progress
        }
      });
    }

    const isAnimate = () => {

      if (stopEvent !== 0) return
      stopEvent++

      const wrapper = this.arrowWrapper.current;
      const texts = this.texts.current;
      const phones = this.phones.current;


      this.animate({
        duration: 600,
        draw(progress) {

          wrapper.style.transform = `translateX(${-progress * wrapper.children[0].offsetWidth}px)`
          wrapper.children[0].classList.add('hidden')

          texts.children[0].style.opacity = 1 - (progress * 2)
          phones.children[0].style.opacity = 1 - (progress * 2)

          if (progress === 1) {

            wrapper.children[0].classList.remove('active')
            wrapper.appendChild(wrapper.children[0])

            wrapper.style.transform = `translateX(0px)`

            wrapper.children[0].classList.add('active')
            wrapper.children[1].classList.remove('hidden')

            texts.children[0].style.display = 'none'
            texts.children[1].style.display = 'block'
            texts.appendChild(texts.children[0])


            phones.children[0].style.display = 'none'
            phones.children[1].style.display = 'block'
            phones.appendChild(phones.children[0])

            fadeOut()

            stopEvent = 0
          }
        }
      })
    }

    return (
      <section className="slider slider-business">
        <div className="container">
          <div className="slider-header" onClick={isAnimate}>
            <div className="slider-header__items" ref={this.arrowWrapper}>

              <div className="slider-header__item active">
                <div className="slider-header__block">
                  <div className="slider-header__title">{t("bussines.slider_title_1")}</div>
                  <div className="slider-header__arrow"></div>
                </div>
                <div className="slider-header__subtitle">{t("bussines.slider_desc_1")}</div>
              </div>

              <div className="slider-header__item">
                <div className="slider-header__block">
                  <div className="slider-header__title">{t("bussines.slider_title_2")}</div>
                  <div className="slider-header__arrow"></div>
                </div>
                <div className="slider-header__subtitle">{t("bussines.slider_desc_2")}</div>
              </div>

              <div className="slider-header__item hidden">
                <div className="slider-header__block">
                  <div className="slider-header__title">{t("bussines.slider_title_3")}</div>
                  <div className="slider-header__arrow"></div>
                </div>
                <div className="slider-header__subtitle">{t("bussines.slider_desc_3")}</div>
              </div>

            </div>
          </div>

          <div className="slider-main">
            <div className="slider-main__content">
              <div className="slider-main__items" ref={this.texts}>

                <div className="slider-main__item">
                  <div className="slider-main__title">{t("bussines.slider_body_1")}</div>
                </div>

                <div className="slider-main__item">
                  <div className="slider-main__title">{t("bussines.slider_body_2")}</div>
                </div>

                <div className="slider-main__item">
                  <div className="slider-main__title">{t("bussines.slider_body_3")}</div>
                </div>

              </div>
              <div className="slider-main__buttons">
                <Buttons onDark onReg />
              </div>
            </div>
            <div className="slider-main__phone" ref={this.phones}>
              <img className="slider-phone__item" src={`./img/slider_business/01/${this.props.language}.jpeg`} alt="Paņem" />
              <img className="slider-phone__item" src={`./img/slider_business/02/${this.props.language}.jpeg`} alt="Brauc" />
              <img className="slider-phone__item" src={`./img/slider_business/03/${this.props.language}.jpeg`} alt="Atstāj" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  language: state.language
})

export default withTranslation()(
  connect(mapStateToProps)(Slider)
)