import { useLocation } from "react-router-dom"
import { Trans, useTranslation } from 'react-i18next'

import { useEffect, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { copyTextToClipboard } from '../../../utils/copyTextToClipboard'


const InformationItem = ({ location, number = 1, count = false }) => {
  const { t } = useTranslation()
  const loc = useLocation()

  const locItem = location.slice(1).split('/')[1]
  // console.log(location)
  // console.log(locItem)

  const [activeTab, setActiveTab] = useState()
  const [copiedLink, setCopiedLink] = useState('default')

  useEffect(() => {
    if (loc.hash) setActiveTab(loc.hash.slice(1))
  }, [loc])

  useEffect(() => {
    if (copiedLink !== 'default')
      setTimeout(() => {
        setCopiedLink('default')
      }, 500)
  }, [copiedLink])

  const showInfo = (v) => {
    if (v === activeTab) setActiveTab(null)
    else setActiveTab(v)
  }

  return (
    <div className="information-content">
      <HashLink to={`/faq#${locItem}`}>
        <div className="information-block active" id='title'>
          <h2 className="information-title"><span>{number}.</span> {t(`faq.${locItem}.title`)}</h2>
          <div className="information-arrow"></div>
        </div>
      </HashLink>

      <div className={(count && !(locItem === 'help-on-the-roads')) ? "information-items border" : "information-items"}>

        {/* questions / parkings / electric */}
        {count && !(locItem === 'help-on-the-roads' || locItem === 'top5') && [...Array(count).keys()].map(i => {
          return (
            <div
              className={`information-item ${+activeTab === i + 1 ? 'active' : ''}`}
              key={t(`faq.${locItem}.${i + 1}.title`)}
              id={`${i + 1}`}
            >
              <dt>
                <div className="information-item__link" onClick={() => {
                  copyTextToClipboard(`https://carguru.lv${loc.pathname}#${i + 1}`, setCopiedLink)
                }} title={`https://carguru.lv${loc.pathname}#${i + 1}`}>
                  {copiedLink === 'default' && (
                    <svg className={''} width="80" height="80" viewBox="0 0 80 80" fill="none" >
                      <path d="M25.6592 48L25.6592 58C25.6592 65.732 31.9272 72 39.6592 72V72C47.3912 72 53.6592 65.732 53.6592 58L53.6592 48" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M25.6592 32L25.6592 22C25.6592 14.268 31.9272 8 39.6592 8V8C47.3912 8 53.6592 14.268 53.6592 22L53.6592 32" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M39.6592 53L39.6592 27" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                  {copiedLink === 'success' && (
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.1214 40.092L29.6777 55.6484C30.4588 56.4294 31.7251 56.4294 32.5062 55.6484L65.0331 23.1214" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                  {copiedLink === 'error' && (
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.4437 24.5566L38.5858 38.6988L41.4142 41.5272L55.5564 55.6693" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M55.5563 24.5566L41.4142 38.6988L38.5858 41.5272L24.4436 55.6693" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                </div>

                <button className="information-item__title" onClick={() => showInfo(i + 1)}>
                  <span>
                    <Trans>
                      {t(`faq.${locItem}.${i + 1}.title`)}
                    </Trans>
                  </span>
                  <svg className="information-item__arrow" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </dt>

              <dd>
                <div className="information-item__text">
                  <Trans>
                    {t(`faq.${locItem}.${i + 1}.subtitle`)}
                  </Trans>

                  {t(`faq.${locItem}.${i + 1}.type`, { ignoreJSONStructure: true }) === 'link' && t(`faq.${locItem}.${i + 1}.link`) === 'phone' && (
                    <a href={`tel:${t("contacts.phone")}`} className='information-item__text-link'>{t("contacts.phone")}</a>
                  )}

                  {t(`faq.${locItem}.${i + 1}.type`) === 'link' && t(`faq.${locItem}.${i + 1}.link`) !== 'phone' && t(`faq.${locItem}.${i + 1}.link`) !== 'file' && (
                    <Trans>
                      <span>
                        <HashLink to={`${t(`faq.${locItem}.${i + 1}.link`)}#title`} className='information-item__text-link'>https://carguru.lv{t(`faq.${locItem}.${i + 1}.link`)}</HashLink>
                      </span>
                    </Trans>
                  )}

                  {t(`faq.${locItem}.${i + 1}.type`) === 'link' && t(`faq.${locItem}.${i + 1}.link`) === 'file' && (<>
                    <Trans>
                      <a
                        href={`/docs/parkinglist/parkinglist.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="information-item__text-link"
                      >{t(`faq.${locItem}.${i + 1}.text_link`)}</a>
                    </Trans>

                    {t(`faq.${locItem}.${i + 1}.subtitle_1`) !== `faq.${locItem}.${i + 1}.subtitle_1` && (
                      <Trans>{t(`faq.${locItem}.${i + 1}.subtitle_1`)}</Trans>
                    )}
                  </>)}
                </div>
              </dd>
            </div>
          )
        })}

        {/* bonuses-and-levels */}
        {!count && (
          <Trans>{t(`faq.${locItem}.text`)}</Trans>
        )}

        {/* top5 */}
        {count && locItem === 'top5' && [...Array(count).keys()].map(i => {
          return (
            <div
              className={`information-item ${+activeTab === i + 1 ? 'active' : ''}`}
              key={t(`faq.questions.${t(`faq.top5.${i + 1}`)}.title`)}
              id={`${i + 1}`}
            >
              <dt>
                <div className="information-item__link" onClick={() => {
                  copyTextToClipboard(`https://carguru.lv${loc.pathname}#${i + 1}`, setCopiedLink)
                }} title={`https://carguru.lv${loc.pathname}#${i + 1}`}>
                  {copiedLink === 'default' && (
                    <svg className={''} width="80" height="80" viewBox="0 0 80 80" fill="none" >
                      <path d="M25.6592 48L25.6592 58C25.6592 65.732 31.9272 72 39.6592 72V72C47.3912 72 53.6592 65.732 53.6592 58L53.6592 48" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M25.6592 32L25.6592 22C25.6592 14.268 31.9272 8 39.6592 8V8C47.3912 8 53.6592 14.268 53.6592 22L53.6592 32" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M39.6592 53L39.6592 27" stroke="#E01254" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                  {copiedLink === 'success' && (
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.1214 40.092L29.6777 55.6484C30.4588 56.4294 31.7251 56.4294 32.5062 55.6484L65.0331 23.1214" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                  {copiedLink === 'error' && (
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.4437 24.5566L38.5858 38.6988L41.4142 41.5272L55.5564 55.6693" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M55.5563 24.5566L41.4142 38.6988L38.5858 41.5272L24.4436 55.6693" stroke="#C2CCDE" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  )}
                </div>

                <button className="information-item__title" onClick={() => showInfo(i + 1)}>
                  <span>
                    <Trans>
                      {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.title`)}
                    </Trans>
                  </span>
                  <svg className="information-item__arrow" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#E01254" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </dt>

              <dd>
                <div className="information-item__text">
                  <Trans>
                    {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.subtitle`)}
                  </Trans>

                  {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.type`, { ignoreJSONStructure: true }) === 'link' && t(`faq.questions.${t(`faq.top5.${i + 1}`)}.link`) === 'phone' && (
                    <a href={`tel:${t("contacts.phone")}`} className='information-item__text-link'>{t("contacts.phone")}</a>
                  )}

                  {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.type`) === 'link' && t(`faq.questions.${t(`faq.top5.${i + 1}`)}.link`) !== 'phone' && t(`faq.questions.${t(`faq.top.${i + 1}`)}.link`) !== 'file' && (
                    <Trans>
                      <span>
                        <HashLink to={`${t(`faq.questions.${t(`faq.top5.${i + 1}`)}.link`)}#title`} className='information-item__text-link'>https://carguru.lv{t(`faq.questions.${t(`faq.top5.${i + 1}`)}.link`)}</HashLink>
                      </span>
                    </Trans>
                  )}

                  {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.type`) === 'link' && t(`faq.questions.${t(`faq.top5.${i + 1}`)}.link`) === 'file' && (<>
                    <Trans>
                      <a
                        href={`/docs/parkinglist/parkinglist.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="information-item__text-link"
                      >{t(`faq.questions.${t(`faq.top5.${i + 1}`)}.text_link`)}</a>
                    </Trans>

                    {t(`faq.questions.${t(`faq.top5.${i + 1}`)}.subtitle_1`) !== `faq.questions.${t(`faq.top5.${i + 1}`)}.subtitle_1` && (
                      <Trans>{t(`faq.questions.${t(`faq.top5.${i + 1}`)}.subtitle_1`)}</Trans>
                    )}
                  </>)}
                </div>
              </dd>
            </div>
          )
        })}


        {/* help-on-the-roads */}
        {locItem === 'help-on-the-roads' && (
          <ol>
            {[...Array(count).keys()].map(i => {
              const text = 'text_' + (i + 1)
              const phone = 'phone_' + (i + 1)
              return (
                <li key={i + 1}>
                  <span>
                    {t(`faq.${locItem}.${text}`)} <a href={'tel:' + t(`faq.${locItem}.${phone}`)}>{t(`faq.${locItem}.${phone}`)}</a>
                  </span>
                </li>
              )
            })}
          </ol>
        )}

      </div>
    </div >
  )
}
export default InformationItem;



