import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './error.css'
import bg from './bg.png'

const Error = ({ resetError = null, countError = null, mobile = null, dark = false }) => {
  const [title, setTitle] = useState('Pieprasītais URL <br/> šajā serverī netika atrasts.')
  const [subtitle, setSubtitle] = useState('Dodieties uz mājaslapu')

  const language = useSelector((state) => state.language)
  const hasCookies = useSelector((state) => state.cookies)

  useEffect(() => {
    if (navigator.cookieEnabled) {
      const changeLanguage = (lng) => {
        if (lng === 'ru') {
          setTitle('Запрошенный URL <br/> не найден на этом сервере.')
          setSubtitle('Перейти на главную страницу')
        } else if (lng === 'en') {
          setTitle('The requested URL <br/> was not found on this server.')
          setSubtitle('Go to main page')
        } else {
          setTitle('Pieprasītais URL <br/> šajā serverī netika atrasts.')
          setSubtitle('Dodieties uz mājaslapu')
        }
      }

      if (hasCookies && localStorage.getItem('i18nextLng')) {
        changeLanguage(localStorage.getItem('i18nextLng'))
      } else if (language) {
        changeLanguage(language)
      } else {
        changeLanguage('lv')
      }
    } else {
      setTitle('Please enable them for this web site <br/> and reload the page.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetErrorFunc = () => {
    if (mobile) return
    else {
      if (resetError && countError) {
        if (countError === 1) {
          resetError()
        } else {
          window.history.pushState({}, '', '/')
          window.location.reload()
        }
      } else {
        window.history.pushState({}, '', '/')
        window.location.reload()
      }
    }
  }


  return (
    <div className="error" style={{ backgroundImage: `url(${bg})`, paddingTop: (mobile) ? '68px' : '40px' }}>
      <div className="error-logo" onClick={resetErrorFunc}>
        <svg width="60" height="41" viewBox="0 0 60 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M35.0012 37.8762C33.1329 40.6255 29.9676 41.6648 27.9365 40.2013C25.9029 38.7354 25.7729 35.3223 27.6406 32.5723C29.5135 29.8253 32.6759 28.7837 34.71 30.2472C36.7412 31.7131 36.8741 35.1262 35.0012 37.8762Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M53.9769 37.8762C52.1039 40.6255 48.9416 41.6648 46.9075 40.2013C44.8763 38.7354 44.7445 35.3223 46.6163 32.5723C48.4845 29.8253 51.6498 28.7837 53.681 30.2472C55.7145 31.7131 55.8451 35.1262 53.9769 37.8762Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M43.4812 26.0387C40.3135 26.0387 37.7447 23.3957 37.7447 20.1341C37.7447 16.8749 40.3135 14.2319 43.4812 14.2319C46.6482 14.2319 49.2171 16.8749 49.2171 20.1341C49.2171 23.3957 46.6482 26.0387 43.4812 26.0387ZM59.2876 19.708C58.9129 11.1414 51.9118 4.30273 43.6018 4.30273C43.49 4.30273 43.3753 4.30273 43.2618 4.30511C39.8347 4.38355 36.5959 5.58384 33.8947 7.78298L20.2812 18.9237C19.8282 19.2951 19.5688 19.8483 19.5688 20.4431C19.5688 21.0379 19.8282 21.5911 20.2812 21.9619L28.7071 28.8576C29.9188 28.0697 31.2647 27.6246 32.5847 27.6246C33.7647 27.6246 34.8759 27.9776 35.8006 28.6431C37.8141 30.0953 38.5847 32.7918 38.0335 35.5227C39.7318 36.1889 41.5347 36.5507 43.3982 36.5763C43.4118 34.8246 43.9894 33.0176 45.0559 31.4501C46.6612 29.0905 49.1524 27.6246 51.5576 27.6246C52.7382 27.6246 53.8512 27.9776 54.7741 28.6431C55.3341 29.0465 55.7959 29.5492 56.1629 30.1179C58.3488 27.1362 59.4506 23.5056 59.2876 19.708Z" fill="#E01254" />
          <path fillRule="evenodd" clipRule="evenodd" d="M27.8964 29.4549L15.6375 19.464L31.5564 6.43488C34.3293 4.17869 37.6528 2.94572 41.1693 2.86728C41.2858 2.86491 41.4022 2.86253 41.5187 2.86253C50.0528 2.86253 57.2428 9.88482 57.6252 18.6772C57.7828 22.3078 56.8122 25.7881 54.8687 28.7222C55.5046 29.2012 56.0105 29.8096 56.3869 30.5037C58.8234 27.0626 60.0511 22.9115 59.8634 18.5762C59.4269 8.55737 51.2399 0.557617 41.5187 0.557617C41.3869 0.557617 41.2546 0.559994 41.1199 0.562371C37.1111 0.652095 33.3252 2.05857 30.1652 4.63028L14.2446 17.6612C13.714 18.0925 13.4111 18.7402 13.4111 19.436C13.4111 20.1324 13.714 20.7772 14.2422 21.2109L26.3328 31.1044C26.7964 30.4805 27.3252 29.9273 27.8964 29.4549Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M43.3961 36.9451C43.3826 36.5969 43.4002 36.2445 43.432 35.8921C42.8067 35.9676 42.1732 36.0092 41.5349 36.0092C40.3344 36.0092 39.1579 35.8642 38.0161 35.6016C37.8591 36.3408 37.6079 37.0788 37.2544 37.796C38.6408 38.1364 40.072 38.3141 41.5349 38.3141C42.2179 38.3141 42.892 38.2725 43.5591 38.1964C43.472 37.7936 43.4138 37.3782 43.3961 36.9451Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M41.5188 2.86253H28.51C27.8918 2.86253 27.3894 2.34617 27.3894 1.71037C27.3894 1.07398 27.8918 0.557617 28.51 0.557617H41.5188C42.1371 0.557617 42.6388 1.07398 42.6388 1.71037C42.6388 2.34617 42.1371 2.86253 41.5188 2.86253Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M32.5012 31.8537H21.0135C20.3976 31.8537 19.8953 31.3403 19.8953 30.7015C19.8953 30.0651 20.3976 29.5518 21.0135 29.5518H32.5012C33.1194 29.5518 33.6212 30.0651 33.6212 30.7015C33.6212 31.3403 33.1194 31.8537 32.5012 31.8537Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M51.7188 31.8537H44.5476C43.9294 31.8537 43.427 31.3403 43.427 30.7015C43.427 30.0651 43.9294 29.5518 44.5476 29.5518H51.7188C52.3376 29.5518 52.8388 30.0651 52.8388 30.7015C52.8388 31.3403 52.3376 31.8537 51.7188 31.8537Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M39.3285 31.8929C38.7238 31.8929 38.2261 31.395 38.2108 30.7681C38.1949 30.1317 38.6855 29.6041 39.3038 29.5904L40.9208 29.5518C41.5279 29.5328 42.052 30.0402 42.0655 30.676C42.0791 31.3118 41.5908 31.8401 40.9726 31.8537L39.3555 31.8929H39.3285Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M15.7744 31.7894H12.2555C11.6349 31.7894 11.1355 31.273 11.1355 30.6372C11.1355 30.0008 11.6349 29.4868 12.2555 29.4868H15.7744C16.3926 29.4868 16.8944 30.0008 16.8944 30.6372C16.8944 31.273 16.3926 31.7894 15.7744 31.7894Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M29.6116 13.9046H18.1216C17.5034 13.9046 17.0016 13.3882 17.0016 12.7524C17.0016 12.116 17.5034 11.6021 18.1216 11.6021H29.6116C30.2281 11.6021 30.7316 12.116 30.7316 12.7524C30.7316 13.3882 30.2281 13.9046 29.6116 13.9046Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M12.8849 13.8401H9.36429C8.74547 13.8401 8.24371 13.3237 8.24371 12.6879C8.24371 12.0515 8.74547 11.5352 9.36429 11.5352H12.8849C13.5031 11.5352 14.0049 12.0515 14.0049 12.6879C14.0049 13.3237 13.5031 13.8401 12.8849 13.8401Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M23.7483 6.80156H15.6354C15.0171 6.80156 14.5154 6.2852 14.5154 5.6494C14.5154 5.01301 15.0171 4.49902 15.6354 4.49902H23.7483C24.3689 4.49902 24.8677 5.01301 24.8677 5.6494C24.8677 6.2852 24.3689 6.80156 23.7483 6.80156Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M16.659 23.024H8.54429C7.92606 23.024 7.42665 22.5083 7.42665 21.8719C7.42665 21.2355 7.92606 20.7197 8.54429 20.7197H16.659C17.2796 20.7197 17.7814 21.2355 17.7814 21.8719C17.7814 22.5083 17.2796 23.024 16.659 23.024Z" fill="#343534" />
          <path fillRule="evenodd" clipRule="evenodd" d="M2.94924 23.024H1.17747C0.559237 23.024 0.0574722 22.5083 0.0574722 21.8719C0.0574722 21.2355 0.559237 20.7197 1.17747 20.7197H2.94924C3.56747 20.7197 4.0663 21.2355 4.0663 21.8719C4.0663 22.5083 3.56747 23.024 2.94924 23.024Z" fill="#343534" />
        </svg>
      </div>

      <h1 className="error-title">404</h1>

      <h2 className="error-subtitle">
        <div style={{ color: (dark) ? '#FFFFFF' : '#2F3059' }} dangerouslySetInnerHTML={{ __html: title }} />

        {(!mobile) && (<span onClick={resetErrorFunc}>{subtitle}</span>)}
      </h2>

    </div>
  )
}
export default Error