import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CarItem from './components/CarItem'

import './cars.sass'


const CarsBlock = () => {
  const { t } = useTranslation()
  const cars = useSelector((state) => state.cars)

  return (
    <section className="cars">
      <div className="container">
        <div className="cars-title">{t("main.model")}</div>
        <div className="cars-block">

          {cars.map((car, index) => <CarItem
            car={car}
            key={`${car.title}_${index}`}
          />
          )}

          <div className="cars-item">
            <div className="cars-item__text">
              <div className="cars-item__title">{t("main.soon_new")}</div>
              <div className="cars-item__price"></div>
            </div>
            <div className="cars-item__img">
              <img src="./img/cars/car.png" alt={t("main.soon_new")} />
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default CarsBlock