import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './popup.sass';
import './animate.css';


const Popup = ({ onClose }) => {
  const { t } = useTranslation();

  const onOverlay = (e) => {
    if (e.target === e.currentTarget) onClose()
  }

  return (
    <div className="popup">
      <div className="popup-overlay" onClick={onOverlay}>
        <div className="popup-wrapper">
          <div
            onClick={onClose}
            className="popup-close"
          >
            <svg id="times" viewBox="0 0 352 512"><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
          </div>

          <span className="popup-title">{t("popup.title.current")}</span>
          <Link to='/login' onClick={() => onClose()}>
            <span className="popup-subtitle">{t("popup.subtitle")}</span>
            <div className="popup-arrow"></div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Popup;