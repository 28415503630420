import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ratesDefault, hidePromoRate } from '../../redux/actions'
import { useLocation } from 'react-router'

import RatesItem from './components/RatesItem'

import './rates.sass'


const Rates = () => {
  const { t } = useTranslation()
  const cars = useSelector((state) => state.cars)
  const ratesSwitch = useSelector((state) => state.rates)
  const hasCookies = useSelector((state) => state.cookies)

  const showPromo = useSelector((state) => state.showPromoRate)
  const [stylePromo, setStylePromo] = useState(false)

  const dispatch = useDispatch()

  const { hash } = useLocation()

  useEffect(() => {
    if (hasCookies && !localStorage.getItem('hidePromoRate'))
      dispatch(hidePromoRate(true))
    dispatch(ratesDefault())

    if (hash === '')
      window.scrollTo(0, 0)
    else
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = `${t("nav.rates")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const promoClose = () => {
    if (hasCookies) localStorage.setItem('hidePromoRate', true)
    setStylePromo(true)

    setTimeout(() => dispatch(hidePromoRate(false)), 1000)
  }


  return (
    <div className="rates">
      {showPromo && (
        <div
          className={`rates-promo desk ${stylePromo ? 'disable' : ''}`}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + './img/rates/bg/promo.svg'})` }}
        >
          <div className="rates-promo__text">
            <Trans>{t("rate.header.promo")}</Trans>
          </div>
          <div
            onClick={promoClose}
            className="rates-promo__close">
            <svg id="times" viewBox="0 0 352 512"><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
          </div>
        </div>
      )}

      <div className="rates-header">
        <div>{t("rate.header.models")}</div>
        <div>{t("rate.header.price")}</div>
        <div>{t("rate.header.24h")}</div>
      </div>
      <div className="rates-block">

        {cars.map((car, index) =>
          <RatesItem
            car={car}
            key={`${car.id}_${index}`}
            ratesSwitch={ratesSwitch}
          />
        )}

      </div>
    </div>
  )
}

export default Rates