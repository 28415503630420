import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactVivus from 'react-vivus';
import svg1 from './img/svg1.svg';
import svg2 from './img/svg2.svg';
import svg3 from './img/svg3.svg';

import './features.sass';


const Features = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const icons = document.querySelectorAll('.features-item__icon');
    const iconsBlock = document.querySelector('.features');
    
    window.addEventListener('scroll', animate);
    function animate() {
      if (window.pageYOffset + document.documentElement.clientHeight > iconsBlock.offsetTop + iconsBlock.offsetHeight/2) {
        window.removeEventListener('scroll', animate);
  
        let deadline = 0;
        let timer = setInterval(function() {
          if (deadline === 300) {
            clearInterval(timer);
            return;
          }
          deadline+=5
          
          icons.forEach(icon => {
            icon.style = `stroke-dasharray: 299, 301; stroke-dashoffset: ${300 - deadline};`;
          });
        }, 40);
      }
    }
    animate()
  }, [])

  return (
    <section className="features">
      <div className="container">
        <div className="features-block">
          <div className="features-item">
            <ReactVivus
              id="svg1"
              className="features-item__icon"
              option={{
                file: svg1,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
            
            <div className="features-item__text">{t("main.free_parking")}</div>
          </div>
          <div className="features-item">
            <ReactVivus
              id="svg2"
              className="features-item__icon"
              option={{
                file: svg2,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
            <div className="features-item__text">{t("main.fuel_price")}</div>
          </div>
          <div className="features-item">
            <ReactVivus
              id="svg3"
              className="features-item__icon"
              option={{
                file: svg3,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
            <div className="features-item__text">{t("main.new_car")}</div>
          </div>
        </div>
      </div>
    </section>  
  )
}

export default Features;