import React from 'react';
import './spinner.css';


const Spinner = ({onSmall = false, onButton = false}) => (
  <div className={`spinner ${((onSmall) ? `spinner-small` : '')} ${((onButton) ? `spinner-button` : '')}`}>
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke={`${(onButton) ? '#ffffff' : '#e01254'}`} strokeWidth="5" r="17" strokeDasharray="80.11061266653974 28.703537555513243">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.7042253521126761s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div>
)
export default Spinner;