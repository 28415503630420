import React from 'react'
import { useTranslation } from 'react-i18next'

import './languageSelect.sass'
import { useDispatch, useSelector } from 'react-redux'
import { handleLanguage } from '../../redux/actions'


const LanguageSelect = ({ onDark = false }) => {

  const language = useSelector((state) => state.language)
  const dispatch = useDispatch()


  const { i18n } = useTranslation()

  const changeLanguage = (language) => {
    dispatch(handleLanguage(language))
    i18n.changeLanguage(language)
  }


  return (
    <select className={(onDark ? "language-select language-select--dark" : "language-select")} onChange={(e) => changeLanguage(e.target.value)} value={language}>
      <option value="lv">Latviski</option>
      <option value="ru">Русский</option>
      <option value="en">English</option>
    </select>
  )
}

export default LanguageSelect