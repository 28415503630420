import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './document.sass'


const Document = () => {
  const { t } = useTranslation()
  const language = useSelector((state) => state.language)


  useEffect(() => {
    document.title = `${t("nav.legal")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])


  return (
    <section className="document">
      <div className="container">
        <div className="document-block">
          <div className="document-title">{t("bussines.document_title")}</div>

          <a
            href={`/docs/business/b2bagreement-${language === 'ru' ? 'en' : language}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="document-item"
          >
            <h2 className="document-item__title">{t("bussines.document")}</h2>

            <svg className="document-item__icon" viewBox="0 0 384 512">
              <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" />
            </svg>
          </a>

          {t("bussines.our_app")}
        </div>
      </div>
    </section>
  )
}

export default Document