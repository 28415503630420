import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import ReactVivus from 'react-vivus'

import svg1 from './img/svg1.svg'
import svg2 from './img/svg2.svg'
import svg3 from './img/svg3.svg'

import './bonus.sass'


const Bonus = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const icons = document.querySelectorAll('.bonus-item__icon')
    const iconsBlock = document.querySelector('.bonus-block')

    window.addEventListener('scroll', animate)
    function animate() {
      if (window.pageYOffset + document.documentElement.clientHeight > iconsBlock.offsetTop + iconsBlock.offsetHeight / 2) {
        window.removeEventListener('scroll', animate)

        let deadline = 0
        let timer = setInterval(function () {
          if (deadline === 300) {
            clearInterval(timer)
            return
          }
          deadline += 4

          icons.forEach(icon => {
            icon.style = `stroke-dasharray: 320, 356; stroke-dashoffset: ${300 - deadline};`
          })
        }, 40)
      }
    }
    animate()
  }, [])


  return (
    <section className="bonus">
      <div className="container">
        <div className="bonus-title">{t("bonus.title")}</div>

        <div className="bonus-block">
          <HashLink className="bonus-item" smooth to='/faq/questions#18'>
            <div className="bonus-item__title">
              {t("bonus.block.item_1")}
              <span>{t("nav.more")}</span>
            </div>
            <ReactVivus
              id="svg1"
              className="bonus-item__icon"
              option={{
                file: svg1,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
          </HashLink>

          {/* <HashLink className="bonus-item" smooth to='/information/bonuses-and-levels#title'> */}
          <HashLink className="bonus-item" smooth to='/loyalty/bonuses-and-levels'>
            <div className="bonus-item__title">
              {t("bonus.block.item_2")}
              <span>{t("nav.more")}</span>
            </div>
            <ReactVivus
              id="svg2"
              className="bonus-item__icon"
              option={{
                file: svg2,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
          </HashLink>

          <HashLink className="bonus-item" smooth to='/loyalty/referral-program'>
            <div className="bonus-item__title">
              {t("bonus.block.item_3")}
              <span>{t("nav.more")}</span>
            </div>
            <ReactVivus
              id="svg3"
              className="bonus-item__icon"
              option={{
                file: svg3,
                animTimingFunction: 'EASE',
                type: 'oneByOne',
              }}
            />
          </HashLink>
        </div>

        <div className="bonus-main">
          <Link to='/business'>
            <div className="bonus-main__block">
              <div className="bonus-main__title">
                {t("bonus.item")}
                <span>{t("nav.more")}</span>
              </div>
              <div className="bonus-main__arrow"></div>
            </div>
          </Link>
          <div className="bonus-main__subtitle">{t("bonus.subtitle")}</div>
        </div>

      </div>
    </section>
  )
}

export default Bonus