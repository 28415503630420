import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from 'react-router-dom';

import Page from '../../components/Page/Page';
import Bonus from '../../components/Bonus/Bonus';

import Bonuses from './components/Bonuses/Bonuses';
import Referral from './components/Referral/Referral';

import './loyalty.sass';


const Loyalty = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/loyalty') document.title = `${t("nav.loyalty")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, location])


  return (
    <div className="guide loyalty">
      <Switch>
        <Route exact path='/loyalty'>
          <Page>
            <Link to='/loyalty/bonuses-and-levels' className="guide-block">
              <h2 className="guide-title"><span>1.</span> {t("loyalty.bonuses-and-levels.title")}</h2>
              <div className="guide-arrow"></div>
            </Link>

            <Link to='/loyalty/referral-program' className="guide-block">
              <h2 className="guide-title"><span>2.</span> {t("loyalty.referral-program.title")}</h2>
              <div className="guide-arrow"></div>
            </Link>
          </Page>
        </Route>


        <Route path={`/loyalty/bonuses-and-levels`}>
          <Bonuses />
          <Bonus />
          <Redirect from="/loyalty/bonuses-and-levels/***" to="/loyalty/bonuses-and-levels" />
        </Route>

        <Route path={`/loyalty/referral-program`}>
          <Referral />
          <Redirect from="/loyalty/referral-program/***" to="/loyalty/referral-program" />
        </Route>


        <Redirect from="/loyalty/***" to="/loyalty" />
      </Switch>
    </div>
  )
}

export default Loyalty;