import React, { useEffect } from 'react'
import { Redirect, useLocation } from "react-router"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './legal.sass'
import { Link, Route, Switch } from 'react-router-dom'

import { PrivacyLV } from './Privacy/PrivacyLV'
import { PrivacyEN } from './Privacy/PrivacyEN'

const Legal = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const language = useSelector((state) => state.language)

  useEffect(() => {
    document.title = `${t("nav.legal")} - CARGURU`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  if (location.pathname !== '/legal' && location.pathname !== '/legal/privacy-notice') return <Redirect to="/legal" />

  return (
    <Switch>
      <Route path='/legal' exact >

        <div className="legal">
          <a
            href={`/docs/legal/b2cagreement-${language === 'ru' ? 'en' : language}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="legal-block"
          >
            <h2 className="legal-title"><span>1.</span> {t("legal.agreement")}</h2>

            <svg className="legal-icon" viewBox="0 0 384 512">
              <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" />
            </svg>
          </a>

          <a
            href={`/docs/legal/b2cterms-${language === 'ru' ? 'en' : language}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="legal-block"
          >
            <h2 className="legal-title"><span>2.</span> {t("legal.terms_and_conditions")}</h2>

            <svg className="legal-icon" viewBox="0 0 384 512">
              <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" />
            </svg>
          </a>

          <a
            href={`/docs/legal/b2cpenalty-${language === 'ru' ? 'en' : language}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className="legal-block"
          >
            <h2 className="legal-title"><span>3.</span> {t("legal.list_of_penalties")}</h2>

            <svg className="legal-icon" viewBox="0 0 384 512">
              <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" />
            </svg>
          </a>

          <div className="legal-block links">

            <Link to='/legal/privacy-notice' className="link">
              <h2 className="legal-title">
                <span>4.</span> {t("legal.data_processing_notice")}
              </h2>

              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" class="ai ai-LinkOut"
              >
                <path d="M13.5 10.5L21 3" fill='#FFFFFF' />
                <path d="M16 3h5v5" />
                <path d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5" />
              </svg>
            </Link>

            <a
              href={`/docs/privacy/privacy-${language === 'ru' ? 'en' : language}.pdf`}
              target="_blank" rel="noopener noreferrer"
              className="file"
            >
              <svg className="legal-icon" viewBox="0 0 384 512">
                <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" />
              </svg>
            </a>
          </div>
        </div>
      </Route>

      <Route path='/legal/privacy-notice' exact>
        {language === 'lv' ? <PrivacyLV /> : <PrivacyEN />}
      </Route>
    </Switch>

  )
}

export default Legal