import { useEffect } from 'react'

import Promo from './components/Promo/Promo'
import Features from './components/Features/Features'
import Slider from './components/Slider/Slider'
import Cars from './components/Cars/Cars'
import Map from './components/Map/Map'

import './main.css'




const Main = () => {
  useEffect(() => {
    document.title = "CARGURU"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Promo />
      <Features />
      <Slider />
      <Cars />
      <Map />
    </>
  )
}
export default Main