const carsRequested = (newData) => {
  return {
    type: 'CARS_REQUESTED',
    payload: newData
  }
};

const diffRequested = (newData) => {
  return {
    type: 'DIFF_REQUESTED',
    payload: newData
  }
};

const mapMarkersRequested = (newData) => {
  return {
    type: 'MAP_MARKERS_REQUESTED',
    payload: newData
  }
};

const mapPolygonsRequested = (newData) => {
  return {
    type: 'MAP_POLYGONS_REQUESTED',
    payload: newData
  }
};

const newsRequested = (newData) => {
  return {
    type: 'NEWS_REQUESTED',
    payload: newData
  }
};

const moreNewsRequested = (newData) => {
  return {
    type: 'MORE_NEWS_REQUESTED',
    payload: newData
  }
};

const bonuses = (newData) => {
  return {
    type: 'BONUSES',
    payload: newData
  }
};

const ratesSwitcher = (newData) => {
  return {
    type: 'RATES_SWITCHER',
    payload: newData
  }
};

const ratesDefault = () => {
  return {
    type: 'RATES_DEFAULT'
  }
};

const hidePromoRate = (newData) => {
  return {
    type: 'HIDE_PROMO_RATE',
    payload: newData
  }
};

const dataLoaded = () => {
  return {
    type: 'DATA_LOADED'
  }
};

const dataError = () => {
  return {
    type: 'DATA_ERROR'
  }
};

const handleCookies = (newData) => {
  return {
    type: 'HANDLE_COOKIES',
    payload: newData
  }
};

const handleLanguage = (newData) => {
  return {
    type: 'HANDLE_LANGUAGE',
    payload: newData
  }
};

const handleEvent = (name, data) => {
  return {
    type: 'HANDLE_EVENT',
    payload: {name, data}
  }
};


export {
  carsRequested,
  diffRequested,
  mapMarkersRequested,
  mapPolygonsRequested,
  newsRequested,
  moreNewsRequested,
  bonuses,
  ratesSwitcher,
  ratesDefault,
  hidePromoRate,
  dataLoaded,
  dataError,
  handleCookies,
  handleLanguage,
  handleEvent
}