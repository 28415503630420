import React, { Component } from 'react'
import Error from '../Error/Error'
import './error-boundary.css'

export default class ErrorBoundary extends Component {
	state = {
		error: false,
		count: 1,
	}

	resetError = () => {
		this.setState(prevState => ({
			count: prevState.count + 1,
			error: false,
		}))
	}

	componentDidCatch() {
		this.setState({ error: true })
	}

	render() {
		if (this.state.error) {
			return (
				<div className='error-boundary'>
					<Error countError={this.state.count} resetError={this.resetError} />
				</div>
			)
		}
		return this.props.children
	}
}
