import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Popup from './components/Popup/Popup'

import './promotion.sass'
import { handleEvent } from '../../redux/actions'


const Promotion = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const [showPromoLine, setShowPromoLine] = useState(true)
  const [popupOpened, setPopupOpened] = useState(false)

  const hasCookies = useSelector((state) => state.cookies)
  const { hideEventPopup } = useSelector((state) => state.event)
  const dispatch = useDispatch()

  useEffect(() => {
    let showLine = (location.pathname.slice(1).split('/')[0] !== 'login') && (location.pathname.slice(1).split('/')[0] !== 'event')
    if (showLine)
      setShowPromoLine(true)
    else
      setShowPromoLine(false)

    if (hasCookies && sessionStorage.getItem("hideEventPopup")) {
      if (!hideEventPopup)
        dispatch(handleEvent('hideEventPopup', true))
    } else if (hideEventPopup) {
      hasCookies && sessionStorage.setItem("hideEventPopup", true)
    }

    if (
      !((hasCookies && sessionStorage.getItem("hideEventPopup")) || hideEventPopup)
      && showPromoLine
      && (location.pathname === '/')
    ) {
      setTimeout(() => { togglePopup(true) }, 800)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const togglePopup = (show) => {
    const scrollWidth = () => {
      let div = document.createElement('div')
      div.style.width = '50px'
      div.style.height = '50px'
      div.style.overflowY = 'scroll'
      div.style.visibility = 'hidden'

      document.body.appendChild(div)
      let scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()

      return scrollWidth
    }

    if (show) {
      document.body.style.overflow = 'hidden'
      document.body.style.marginRight = `${scrollWidth()}px`
      setPopupOpened(true)

      const escapeDown = (e) => {
        if (e.code === 'Escape') {
          togglePopup(false)
          window.removeEventListener('keyup', escapeDown)
        }
      }
      window.addEventListener('keyup', escapeDown)
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.marginRight = '0px'
      setPopupOpened(false)

      hasCookies && sessionStorage.setItem("hideEventPopup", true)
      dispatch(handleEvent('hideEventPopup', true))
    }
  }
  const hidePopup = () => togglePopup(false)

  return (
    ((showPromoLine) && (
      <>
        <div className="promotion">
          <Link to='/login'>
            <div className="container">
              <div className="promotion-block">
                <span>{t("promo.current")}</span>
                <div className="promotion-arrow"></div>
              </div>
            </div>
          </Link>
        </div>
        <CSSTransition
          in={popupOpened}
          timeout={300}
          classNames="popup-animation"
          unmountOnExit
        >
          <Popup onClose={hidePopup} />
        </CSSTransition>
      </>
    ))
  )
}

export default Promotion