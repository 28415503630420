import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


const GuideItem = ({ location, number = 1 }) => {
  const { t } = useTranslation()
  const language = useSelector((state) => state.language)


  const locItem = location.slice(1).split('/')[1]

  if (number === 6) return (
    <div className="guide-info">
      <Link to='/guide' className="guide-block active">
        <h2 className="guide-title"><span>{number}.</span> {t(`guide.${locItem}.title`)}</h2>
        <div className="guide-arrow"></div>
      </Link>

      <div className="guide-items">
        <div className="guide-item__text notification">
          <br />
          <Trans>
            {t(`guide.${locItem}.text`)}
          </Trans>
        </div>
      </div>
    </div>
  )

  return (
    <div className="guide-info">
      <Link to='/guide' className="guide-block active">
        <h2 className="guide-title"><span>{number}.</span> {t(`guide.${locItem}.title`)}</h2>
        <div className="guide-arrow"></div>
      </Link>

      <div className="guide-items">
        <div className="guide-item">
          <span className="guide-item__num">1.</span>
          <div className="guide-item__img">
            {(locItem) && (
              <img src={`/img/guide/${locItem}/1/${language}.jpg`} alt="registration" />
            )}
          </div>
          <div className="guide-item__text">
            {t(`guide.${locItem}.text_1`)}
          </div>
        </div>

        <div className="guide-item">
          <span className="guide-item__num">2.</span>
          <div className="guide-item__img">
            {(locItem) && (
              <img src={`/img/guide/${locItem}/2/${language}.jpg`} alt="registration" />
            )}
          </div>
          <div className="guide-item__text">
            {t(`guide.${locItem}.text_2`)}
          </div>
        </div>
      </div>
    </div>
  )
}
export default GuideItem