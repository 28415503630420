import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './features.sass';


const Features = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const icons = document.querySelectorAll('.features-item__icon');
    const iconsBlock = document.querySelector('.features');
    
    window.addEventListener('scroll', animate);
    function animate() {
      if (window.pageYOffset + document.documentElement.clientHeight > iconsBlock.offsetTop + iconsBlock.offsetHeight/2) {
        window.removeEventListener('scroll', animate);
  
        let deadline = 0;
        let timer = setInterval(function() {
          if (deadline === 300) {
            clearInterval(timer);
            return;
          }
          deadline+=5
          
          icons.forEach(icon => {
            icon.style = `stroke-dasharray: 299, 301; stroke-dashoffset: ${300 - deadline};`;
          });
        }, 40);
      }
    }
    animate()
  }, [])

  return (
    <section className="features features-business">
      <div className="container">
        <div className="features-block">
          <div className="features-item">
            <svg className="features-item__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145" 
              style={{ strokeDasharray: "299, 301", strokeDashoffset: "300" }}
            >
              <g fill="none" strokeWidth="3.685" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                <path stroke="#1D1748" d="M73.273 127.401a50.966 50.966 0 0 1-14.647 1.239c-28.099-1.671-49.522-25.803-47.852-53.901 1.671-28.098 25.803-49.522 53.901-47.851 28.098 1.671 49.521 25.803 47.851 53.902a50.755 50.755 0 0 1-12.779 30.832M93.334 117.685a51.123 51.123 0 0 1-11.018 6.68M84.771 21.409c10.665 4.381 19.812 11.701 26.435 20.953M61.65 16.867c4.927 0 9.717.585 14.305 1.69M20.122 77.765a41.34 41.34 0 0 1 6.636-22.529M32.832 47.863a41.736 41.736 0 0 1 4.553-3.803"></path>
                <path stroke="#E11254" d="M91.315 93.496c-5.64 10.612-16.809 17.84-29.665 17.84-13.046 0-24.353-7.441-29.909-18.312"></path>
                <path stroke="#E11254" d="M35.711,66.21A8.833,8.833 0,1,1 53.376999999999995,66.21A8.833,8.833 0,1,1 35.711,66.21"></path>
                <path stroke="#E11254" d="M26.909 95.819l10.042-5.808M96.243 95.819l-10.042-5.808M72.168 66.21h17.356"></path>
              </g>
            </svg>
            <div className="features-item__text">{t("bussines.features_1")}</div>
          </div>
          <div className="features-item">
            <svg className="features-item__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145" 
              style={{ strokeDasharray: "462, 464", strokeDashoffset: "462" }}
            >
              <g fill="none" strokeWidth="3.685" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                <path stroke="#1D1748" d="M90 124.667a4 4 0 0 1-4 4H16.334a4 4 0 0 1-4-4V21.318a4 4 0 0 1 4-4H86a4 4 0 0 1 4 4v25.21M21.334 30.99H62.5M21.334 46.528H79M21.334 60.897H62.5M21.334 113.803h28.833"></path>
                <path stroke="#E11254" d="M90 61.44c15.188 0 27.5 12.312 27.5 27.5s-12.312 27.5-27.5 27.5c-11.236 0-20.899-6.741-25.166-16.398M76.063 65.229a27.355 27.355 0 0 1 5.834-2.575M62.5 88.94c0-6.316 2.13-12.136 5.711-16.778"></path>
                <g stroke="#E11254">
                  <path d="M81.899 80.661l8.667-7.386M90.566 73.275v28.56M81.899 101.835h17.334"></path>
                </g>
              </g>
            </svg>
            <div className="features-item__text">{t("bussines.features_2")}</div>
          </div>
          <div className="features-item">
            <svg className="features-item__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 145" 
              style={{ strokeDasharray: "299, 301", strokeDashoffset: "300" }}
            >
              <g fill="none" strokeWidth="3.685" strokeLinecap="round" strokeMiterlimit="10">
                <path stroke="#1D1748" d="M23.709000000000003,113.652A9.634,9.634 0,1,1 42.977000000000004,113.652A9.634,9.634 0,1,1 23.709000000000003,113.652"></path>
                <path stroke="#1D1748" d="M39.997 97.903c6.135 2.595 10.438 8.669 10.438 15.749 0 .966-.081 1.913-.235 2.836"></path>
                <path stroke="#1D1748" d="M79.417,113.652A9.634,9.634 0,1,1 98.685,113.652A9.634,9.634 0,1,1 79.417,113.652"></path>
                <path stroke="#1D1748" d="M71.789 116.486c-.842-4.179-.142-7.698 2.16-11.188M51.444 116.486H71.11"></path>
                <path stroke="#1D1748" strokeLinejoin="round" d="M17.186 116.486h-5.792l.567-12.469s1.099-25.698 28.724-25.698H61.56c13.375 0 20.066 10.28 30.75 14.166 17.417 6.334 20.512 11.202 20.128 16.461s-3.503 7.54-3.503 7.54h-4"></path>
                <path stroke="#1D1748" d="M54.061 97.903h25.356M20.739 97.903h9.758M39.997 78.319v19.175"></path>
                <path stroke="#E11254" d="M32.892 68.838a35.745 35.745 0 0 1-.967-17.458c3.749-19.458 22.561-32.192 42.018-28.443 19.458 3.748 32.194 22.56 28.446 42.017a35.726 35.726 0 0 1-8.288 16.919"></path>
                <path stroke="#E11254" d="M42.703 65.267a22.527 22.527 0 0 1-.61-11.01 22.528 22.528 0 0 1 7.151-12.602M66.412 51.308l.123-15.164M72.941 58.822l21.345.174"></path>
                <path stroke="#E11254" d="M59.172999999999995,58.194A6.889,6.889 0,1,1 72.951,58.194A6.889,6.889 0,1,1 59.172999999999995,58.194"></path>
                <path stroke="#1D1748" strokeLinejoin="round" d="M87.426,113.651A1.625,1.625 0,1,1 90.676,113.651A1.625,1.625 0,1,1 87.426,113.651"></path>
                <path stroke="#1D1748" strokeLinejoin="round" d="M31.792,113.651A1.625,1.625 0,1,1 35.042,113.651A1.625,1.625 0,1,1 31.792,113.651"></path>
              </g>
            </svg>
            <div className="features-item__text">{t("bussines.features_3")}</div>
          </div>
        </div>
      </div>
    </section>  
  )
}

export default Features;